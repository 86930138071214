import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../auth.service'
import { TouchSequence } from 'selenium-webdriver'

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.auth.getAuth()
    const inscription = localStorage.getItem('inscription')
    if (token && inscription) {
      this.router.navigate([''])
      return false
    }
    return true
  }
}
