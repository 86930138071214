import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject, TimeoutError } from "rxjs";
import { AuthService } from "@app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService, TabsetComponent } from "ngx-bootstrap";
import { MonCompteService } from "@app/services/mon-compte.service";

// @ts-ignore
// @ts-ignore
@Component({
  selector: "mytribu-reset-password-form",
  templateUrl: "./reset-password-form.component.html",
  styleUrls: ["./reset-password-form.component.scss"],
})
export class ResetPasswordFormComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: BsModalService,
    public monCompte: MonCompteService
  ) {}

  @ViewChild("tabset", { static: true }) tabset: TabsetComponent;
  // @Input() id: any
  lienExpire = false;
  timeLeft = 120; // temps d'expiration en seconde(2 mins)
  interval: any;
  statusToken: string;
  timer: any;
  id: any;
  modalRef?: BsModalRef;
  pass: any = {
    password: "",
    confirmePassword: "",
  };
  resetPass = {
    email: "",
  };
  errorPass: any = "";
  successPass: any = "";
  errormessage: any = "";
  click = false;
  success: any = "";
  showPassword: any = false;
  showConfirmePassword: any = false;
  passPattern =
    "^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$";
  private _unsubscribeAll: Subject<any> = new Subject();
  isElementVisible: boolean = false; // You can set this to true or false initially

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id") || "";
      console.log("Code lien");
      console.log(this.id);
      
    });
    this.interval = setInterval(() => {
      this.countdown();
       }, 1000);
    const now = new Date().toISOString();
    localStorage.setItem("statusToken", now);
     // Here, you can place your logic to determine whether the element should be visible or not.
    // For example, based on a condition:
  
  }

  // Fonction pour calculer la différence de temps et afficher un message si nécessaire
  checkResetPasswordExpiration() {
    const statusToken = localStorage.getItem("statusToken");
    if (statusToken) {
      const now = new Date();
      const expirationDate = new Date(statusToken);
      expirationDate.setMinutes(expirationDate.getMinutes() + 1);
      if (now > expirationDate) {
        return true;
      } else {
        return false;
      }
    }
  }

  Reset(pass) {
    const resetpassword: any = {
      password: this.pass.password,
    };
    console.log('pass',pass)
 /*   if(pass.password =='' || pass.confirmePassword =='') {
      console.log('pas here');
      this.errormessage ='Veuillez remplir tous les champs requis.';
      //this.isElementVisible = !this.isElementVisible;
      console.log(this.isElementVisible)
      
      return false;
        

    }*/
    if (this.pass.password !== "" && this.pass.confirmePassword !== "") {
      //this.isElementVisible = !this.isElementVisible;
     // this.errormessage ='';
      //console.log(this.isElementVisible)
      this.auth
        .resetPassword(this.id, resetpassword)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            /*this.pass.password = '';
            this.pass.confirmePassword = '';
            this.success = "Votre mot de passe a été modifié avec succès";
            const self = this;
      
            setTimeout(() => {
              this.pass.password = '';
              this.pass.confirmePassword = '';
              this.success = "Votre mot de passe a été modifié avec succès";
              self.success = "";
          
              this.router.navigate(["connexion"]);
             
            }, 5000);*/
            this.success = "Votre mot de passe a été modifié avec succès";
            let self = this;
            this.pass.password = "";
            this.pass.confirmePassword = "";
            setTimeout(() => {
              self.success = "";
              this.router.navigate(["connexion"]);
            }, 5000);
            clearTimeout(this.timer);
          },
          (MsgError) => {
            if (
              MsgError.err.error &&
              MsgError.err.error.message === "Password exist."
            ) {
              this.errormessage = `Le mot de passe n'a pas pu être modifié, car ce mot de passe est déjà utilisé.`;
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message === "Mot de passe existe."
            ) {
              this.errormessage = `Le mot de passe n'a pas pu être modifié, car ce mot de passe est déjà utilisé.`;
            } else if (
              MsgError.err.error &&
              this.checkResetPasswordExpiration() === true
            ) {
              this.errormessage = `Le mot de passe n’a pas pu être modifié, car le lien a expiré. Pour recevoir un nouveau lien de réinitialisation de mot de passe,`;
            } else if (MsgError.err.error && this.lienExpire === true) {
              this.errormessage = `Le mot de passe n’a pas pu être modifié, car le lien a expiré. Pour recevoir un nouveau lien de réinitialisation de mot de passe,`;
            }
            this.pass.password = "";
            this.pass.confirmePassword = "";
            setTimeout(() => {
              this.errormessage = "";
            }, 10000);
          }
        );
    }   else {
    this.errormessage =
        this.pass.password == ''
          ? 'Veuillez remplir tous les champs requis.'
          : 'Veuillez remplir tous les champs requis.'
    } 

  

  }

  selectTab(tabId?) {
    const id = tabId.id ? tabId.id : 0;
    this.tabset.tabs[id].active = true;
  }

  openModalAdherent(templateAdherent: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateAdherent);
  }

  /*** Reset Password adherent*/
  ResetpasswordSalarie() {
    this.click = true;
    if (this.resetPass.email !== "") {
      this.monCompte
        .ResetPassword(this.resetPass)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.errorPass = "";
            this.successPass =
              "Un email pour réinitialiser votre mot de passe vous a été envoyé. Pensez à vérifier vos courriers indésirables si vous ne l'avez reçu.";
            this.resetPass.email = "";
            const self = this;
            setTimeout(() => {
              self.successPass = "";
            }, 10000);
          },
          (resError) => {
            if (
              (resError.err.error &&
                resError.err.error.message === "Email incorrecte.") ||
              resError.err.error.message ===
                "Certains champs sont invalides : email"
            ) {
              this.errorPass =
                "Désolé, cette adresse e-mail n'est associée à aucun compte Adhérent.";
            }
          }
        );
    } else {
      this.errorPass =
        this.resetPass.email === "" ? "Ce Champ est obligatoire" : "";
    }
  }

  countdown() {
    if (this.timeLeft > 0) {
     this.timeLeft--;
    } else {
      clearInterval(this.interval);
      this.lienExpire = true;
    }
  }
}
