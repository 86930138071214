import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mytribu-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  pages: any;
  name: any;
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(public auth: AuthService, public activeRoute: ActivatedRoute,    public router: Router) { }

  ngOnInit() {
    this.getDynamicPage();
  }
  navigateGeneriquePage(item) {
    localStorage.setItem('pages', JSON.stringify(item.segment));
    this.router.navigate(['/home/' + item.segment]);
  }
  getDynamicPage() {
    this.auth
    .StaticPageDynamic()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(res => {
      this.pages = res;
    })
}
}
