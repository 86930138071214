import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CarouselModule } from "ngx-bootstrap";
import { NewHomeComponent } from "./new-home.component";
import { SharedModule } from "@app/shared/shared.module";

@NgModule({
  declarations: [NewHomeComponent],
  imports: [CommonModule, SharedModule, CarouselModule],
})
export class NewHomeModule {}
