import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  TemplateRef,HostListener 
} from "@angular/core";
import { SimpleSliderComponent } from "@app/components/simple-slider/simple-slider.component";
import { AuthService } from "@app/services/auth.service";
import { BsModalRef, BsModalService, TabsetComponent } from "ngx-bootstrap";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Router, ActivatedRoute, RoutesRecognized } from "@angular/router";
import { MonCompteService } from "@app/services/mon-compte.service";
import { CardNumeriqueComponent } from "./forms/card-numerique/card-numerique.component";
import { SigninPoppinComponent } from "./forms/signin-poppin/signin-poppin.component";
import { CookieService } from "ngx-cookie-service";

declare var $: any;

@Component({
  selector: "mytribu-connexion",
  templateUrl: "./connexion.component.html",
  styleUrls: ["./connexion.component.scss"],
})
export class ConnexionComponent implements OnInit {
  @ViewChild(SimpleSliderComponent) slider: SimpleSliderComponent;
  @ViewChild("tabset") tabset: TabsetComponent;
  Slider: any = [];
  modalRef?: BsModalRef;
  templatePoppin?: TemplateRef<any>;
  resetpassword: any = false;
  addpassword: any = false;
  id: any;
  idPass: any;
  showPass = false;
  secteur: string = "salarie";
  resetPass = {
    email: "",
  };
  resetPassEm = {
    email: "",
  };
  // teslink: string = `tect <a>ici</a>`;
  errorPass: any = "";
  errorPassEm: any = "";
  sendMail = false;
  click = false;
  successPass: any = "";
  successPassEm: any = "";
  openMenu = true;
  showIndicator = false;
  itemsPerSlide = 4;
  singleSlideOffset = true;
  noWrap = true;
  errormessage: any = "";
  errormessageAdherent: any = "";
  clientIdentifiant: any;
  errormessagePremierCnx: any = "";
  dataPremiere: any = [];
  pages: any;
  // client: any = {
  //   login: "",
  //   password: "",
  // };
  employeur: any = {
    login: "",
    password: "",
  };
  salarie: any = {
    login: "",
    password: "",
    email: "",
    codeUsageUq: ""
  };
   premierCnx: any = {
    email: "",
    codeUsageUq: ""
   }
  requiredCnx = false;
  showPassword: any = false;

  slider_avantages = [
    {
      title: "Parc de loisirs",
      image: "assets/v3/images/avantages/parc.png",
      id_block: "parc",
    },
    {
      title: "Prêt-à-porter",
      image: "assets/v3/images/avantages/pretaporter.png",
      id_block: "pretaporter",
    },
    {
      title: "Sports & Loisirs",
      image: "assets/v3/images/avantages/sports.png",
      id_block: "sports",
    },
    {
      title: "Transports",
      image: "assets/v3/images/avantages/transport.png",
      id_block: "transport",
    },
    {
      title: "Bien-être",
      image: "assets/v3/images/avantages/bienetre.png",
      id_block: "bienetre",
    },
    {
      title: "Cinéma",
      image: "assets/v3/images/avantages/cinema.png",
      id_block: "cinema",
    },
    {
      title: "Concerts & Spectacles",
      image: "assets/v3/images/avantages/concerts.png",
      id_block: "concerts",
    },
    {
      title: "Vie quotidienne",
      image: "assets/v3/images/avantages/viequotidienne.png",
      id_block: "viequotidienne",
    },
    {
      title: "Destination vacances",
      image: "assets/v3/images/avantages/vacances.png",
      id_block: "vacances",
    },
    {
      title: "Location",
      image: "assets/v3/images/avantages/location.png",
      id_block: "location",
    },
  ];

    scrWidth:any;

  constructor(
    public auth: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    public monCompte: MonCompteService,
    public activeRoute: ActivatedRoute,
    private modalService: BsModalService,
    private cookies: CookieService,
  ) {}
  private _unsubscribeAll: Subject<any> = new Subject();
  orderId: any;
  contenu: any = [];

  // tab: number
  secteurName: any;
  classSectBloc1:any;
  classSectBloc2:any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    console.log(this.scrWidth, 'xidth', event)
    if (this.scrWidth < 767){
      const elements = document.querySelectorAll('.secteur-med');
      document.querySelectorAll('.secteur-med').forEach((elem, index)  => {
        elem.classList.remove('align-items-end');
        elem.classList.remove('justify-content-end');
      })

      this.itemsPerSlide = 1;
    } else {
      this.itemsPerSlide = 4;
    }
    console.log(this.itemsPerSlide , 'this.itemsPerSlide')
    return this.itemsPerSlide;
  }
  ngOnInit() {
     
    if (window.screen.width < 767){
      this.itemsPerSlide = 1;
    }else {
      this.itemsPerSlide = 4;
    }
    this.getScreenSize();
    this.route.queryParams.subscribe((params: any) => {
      console.log("Les params");
      console.log(params);
      this.secteurName = params.data;
      if(this.secteurName=="undefined"){
         this.classSectBloc1="d-lg-block";
         this.classSectBloc2="d-lg-none";
      }else{
        this.classSectBloc1="d-lg-none";
        this.classSectBloc2="d-lg-block";

      }

      /*this.classSect ="d-lg-block"
      console.log(   this.secteurName);*/
    });
    this.getDynamicPage();
    var bodyColor = "bodyColorWhite";
    document.getElementsByTagName("body")[0].className = bodyColor;
  }
 

  ngOnDestroy() {
    // remove the the body classes
    var bodyColor = "bodyColor";
    document.getElementsByTagName("body")[0].className = bodyColor;
  }

  selectTab(tabId?) {
    let id = tabId.id ? tabId.id : 0;
    this.tabset.tabs[id].active = true;
  }

  getSlider() {
    this.Slider = [];
    this.auth
      .getSlider()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.Slider = res;
      });
  }
  getDynamicPage() {
    this.auth
      .StaticPageDynamic()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.pages = res;
      });
  }

  LoginAdherent(myformAdherent) {
    localStorage.clear();
    if (myformAdherent.valid == true) {
      this.errormessageAdherent = "";
      if (this.salarie.login != "" && this.salarie.password != "") {
        this.auth
          .LoginClient(this.salarie)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            (res) => {
              console.log(res.user)
              this.auth.setAuth(res.token);
              this.auth.setClient(res.user);
              this.errormessageAdherent = "";
              localStorage.setItem("dossierExpired", res.user.dossierExpired);
              this.clientIdentifiant = res.user.cleAdhesion;
              localStorage.setItem("clientIdentifiant", this.clientIdentifiant);
              localStorage.setItem("idEtabl", res.user.idEtabl);
              localStorage.setItem("idSecteur", res.user.idSecteur);
              localStorage.setItem("nameSecteur", res.user.nameSecteur);
              localStorage.setItem("infoCarte", res.user.infoCarte);
              if (localStorage.getItem("clientNonInscri")) {
                localStorage.removeItem("clientNonInscri");
              }
       
                        
              this.router.navigate(["home"], {
                queryParams: { modalConnexion: "true" },
              });
            },
            (MsgError) => {
              if (
                MsgError.err.error &&
                MsgError.err.error.message == "Mot de passe introuvable."
              ) {
                this.errormessageAdherent =
                  "L’identifiant et/ou le mot de passe saisis sont incorrects.";
              } else if (
                MsgError.err.error &&
                MsgError.err.error.message == "login non trouvé."
              ) {
                this.errormessageAdherent =
                  "L’identifiant et/ou le mot de passe saisis sont incorrects.";
              }
              this.router.navigate(["connexion"]);
            }
          );
      } else {
        this.errormessageAdherent =
          this.salarie.login == ""
            ? "Email où Clé d'adhésion obligatoire"
            : "Mot de passe obligatoire";
      }
    }
  }

  openModal() {
    this.modalRef = this.modalService.show(SigninPoppinComponent, {
      initialState: {
        title: "Modal title",
        data: {},
      },
    });
  }

  // closePoppin() {
  //       this.modalRef = this.modalService.show(SigninPoppinComponent, {
  //     initialState: {
  //       title: "Modal title",
  //       data: {},
  //     },
  //   });
  // }
  /*** Login Employeur */
  LoginClient(myform) {
    localStorage.clear();
    if (myform.valid == true) {
      this.errormessage = "";
      if (this.employeur.login != "" && this.employeur.password != "") {
        this.auth
          .LoginEmployeur(this.employeur)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            (res) => {
              this.auth.setAuth(res.token);
              this.auth.setClient(res.user);

              if (this.auth && res.user.type == 1) {
                this.errormessage = "";
                localStorage.setItem("dossierExpired", res.user.dossierExpired);
                this.clientIdentifiant = res.user.cleAdhesion;
          
                localStorage.setItem("idEtabl", res.user.idEtabl);
                localStorage.setItem("idSecteur", res.user.idSecteur);
                localStorage.setItem("nameSecteur", res.user.nameSecteur);
                localStorage.setItem("infoCarte", res.user.infoCarte);
                localStorage.setItem("clientIdentifiant", this.clientIdentifiant);
                //localStorage.setItem("password",this.employeur.password);
                if (localStorage.getItem("clientNonInscri")) {
                  localStorage.removeItem("clientNonInscri");
                }
                this.router.navigate(["espaceEtablissement"], {
                  queryParams: { modalConnexion: "true" },
                });
              }
              if (this.auth && res.user.type == 2) {
                // alert("Poppin");
                this.openModal();
              } else {
              }
            },
            (MsgError) => {
              if (
                MsgError.err.error &&
                MsgError.err.error.message == "Mot de passe introuvable."
              ) {
                this.errormessage =
                  "L’identifiant et/ou le mot de passe saisis sont incorrects.";
              } else if (
                MsgError.err.error &&
                MsgError.err.error.message == "login non trouvé."
              ) {
                this.errormessage =
                  "L’identifiant et/ou le mot de passe saisis sont incorrects.";
              }else if (
                MsgError.err.error &&
                MsgError.err.error.message == "Compte désactivé."
              ) {
                this.errormessage =
                  "Votre compte n’est plus actif, veuillez contacter notre service client pour une demande de réactivation .";
              }
              this.router.navigate(["connexion"]);
            }
          );
      } else {
        this.errormessage =
          this.employeur.login == ""
            ? "Email où Clé d'adhésion obligatoire"
            : "Mot de passe obligatoire";
      }
    }
  }

  /*** Reset Password employeur*/
  ResetpasswordEmployeur() {
    this.click = true;
    if (this.resetPassEm.email !== "") {
      this.monCompte
        .ResetPasswordEmployeur(this.resetPassEm)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.errorPassEm = "";
            this.successPassEm =
              "Un email pour réinitialiser votre mot de passe vous a été envoyé. Pensez à vérifier vos courriers indésirables si vous ne l'avez pas reçu.";
            this.resetPassEm.email = "";
            let self = this;
            setTimeout(() => {
              self.successPassEm = "";
            }, 10000);
          },
          (resError) => {
            if (
              (resError.err.error &&
                resError.err.error.message == "Email incorrecte.") ||
              resError.err.error.message ==
                "Certains champs sont invalides : email"
            ) {
              this.errorPassEm =
                "Désolé, cette adresse e-mail n'est associée à aucun compte Etablissement.";
            }
            else if (resError.err.error &&
              resError.err.error.message == "Compte inactif.") 
           {
            this.errorPassEm =
              "Votre compte n’est plus actif, veuillez contacter notre service client pour une demande de réactivation .";
          }
          }
        );
    } else {
      this.errorPassEm =
        this.resetPassEm.email == "" ? "Ce Champ est obligatoire" : "";
    }
  }

  /*** Reset Password adherent*/
  ResetpasswordSalarie() {
    this.click = true;
    if (this.resetPass.email !== "") {
      this.monCompte
        .ResetPassword(this.resetPass)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.errorPass = "";
            this.successPass =
              "Un email pour réinitialiser votre mot de passe vous a été envoyé. Pensez à vérifier vos courriers indésirables si vous ne l'avez pas reçu.";
            this.resetPass.email = "";
            let self = this;
            setTimeout(() => {
              self.successPass = "";
            }, 10000);
          },
          (resError) => {
            if (
              (resError.err.error &&
                resError.err.error.message == "Email incorrecte.") ||
              resError.err.error.message ==
                "Certains champs sont invalides : email"
            ) {
              this.errorPass =
                "Désolé, cette adresse e-mail n'est associée à aucun compte Adhérent.";
            }
          }
        );
    } else {
      this.errorPass =
        this.resetPass.email == "" ? "Ce Champ est obligatoire" : "";
    }
  }

  openModalAdherent(templateAdherent: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateAdherent);
  }

  openModalEmployeur(templateEmployeur: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateEmployeur);
  }

  openModalPoppin(templatePoppin: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templatePoppin);
  }

  ferme() {
    this.modalRef.hide();
    this.resetPass.email = "";
    this.resetPassEm.email = "";
  }
  showPwd() {
    this.showPass = false;
  }
  loginPremiereCnx(){
  
    this.requiredCnx = false;
    this.errormessagePremierCnx = "";
    if (this.premierCnx.codeUsageUq != "" && this.premierCnx.email != "") {
      console.log('+++',this.premierCnx)
      this.auth
        .LoginPremiereCnx(this.premierCnx)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            console.log(res,'res connexion')

            this.auth.setAuth(res.token);
            this.auth.setClient(res.carte);
            localStorage.setItem("numeroCarte", res.carte.numeroCarte);
            localStorage.setItem("idCarte", res.carte.idCarte);
            localStorage.setItem("dateExpiration", res.carte.dateExpiration);
            localStorage.setItem("idEtab", res.carte.idEtab);
            localStorage.setItem("nameEtab", res.carte.nameEtab);
            localStorage.setItem("idSecteur", res.carte.idSecteur);
            localStorage.setItem("nameSecteur", res.carte.nameSecteur);
            localStorage.setItem("nameMetier", res.carte.nameMetier);
            localStorage.setItem("idMetier", res.carte.idMetier);
            localStorage.setItem("hasCarte", res.carte.hasCarte);
            localStorage.setItem("codeUsageUq", this.premierCnx.codeUsageUq);
            localStorage.setItem("idAdherent", res.carte.idAdherent);
            localStorage.setItem("email", this.premierCnx.email);
            localStorage.setItem("civilite", res.carte.idCiv);

            // this.modalRef.hide();
            // this.router.navigate(["connexion"]);
            console.log(this.auth, 'auth')
            console.log(localStorage, 'localStorage')

            this.router.navigate(["premiereConnexion"]);
            // if(res.carte.hasCarte === 0){
            // } else {  
            //   this.router.navigate(["home"]);
            // }
            this.dataPremiere = res.carte.info;
           // this.modalRefMonCompte.hide();
          },
          (MsgError) => {
            if (
              MsgError.err.error &&
              MsgError.err.error.message == "Code usage unique est introuvable"
            ) {
              this.errormessagePremierCnx =
                "Le code à usage unique saisi est incorrect.";
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message == "Carte est expiré."
            ) {
              this.errormessagePremierCnx =
                "La carte est expirée.";
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message == "Email incorrecte"
            ) {
              this.errormessagePremierCnx =
                "L\'e-mail saisi est incorrect";
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message == "email existe"
            ) {
              this.errormessagePremierCnx =
                "L\'e-mail saisi existe déjà";
            }else if (
              MsgError.err.error &&
              MsgError.err.error.message == "Etablissement désactivé"
            ) {
              this.errormessagePremierCnx =
                "Etablissement est désactivé";
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message == "Code usage unique est utilisé"
            ){
              this.errormessagePremierCnx =
              "Ce code a déjà été utilisé, veuillez renseigner un autre code à usage unique.";
            }
            this.router.navigate(["connexion"]);
          }
        );
    } else {
      this.errormessagePremierCnx = 
        this.premierCnx.codeUsageUq == ""
          ? "Code à usage unique est obligatoire"
          : "Email obligatoire";
    }
  }
 /* navigateGeneriquePage(item,id_block){

    console.log("navigateGeneriquePage")
    console.log(item)
    console.log(id_block)
    localStorage.setItem('pages', JSON.stringify(item));
    //this.router.navigate(['/home/' + item.segment]);
     // Encodez le caractère '#' dans l'identifiant du bloc
     let url = `/home/${item.segment}#${encodeURIComponent(id_block)}`;
     console.log(url)
     this.router.navigate([url]);
    
  }*/

  navigateToLocation() {
    // Appel de la fonction de navigation avec le segment 'Nos-partenaires' et l'ID 'location'
    this.navigateGeneriquePage('Nos-partenaires', 'location');
  }

  navigateGeneriquePage(segment: string, id_block: string) {
    // Encodez le caractère '#' dans l'identifiant du bloc
    const url = `/home/${segment}#${encodeURIComponent(id_block)}`;
    this.router.navigate([url]);
  }
  allerVersNosPartenaires() {
    // Naviguer vers la page "nos-partenaires" avec le fragment "#bloc-partenaires"
    this.router.navigate(['/home/Nos-partenaires'], { fragment: 'viequotidienne' });
  }
}
