import { SigninPoppinComponent } from '../connexion/forms/signin-poppin/signin-poppin.component';
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeaderComponent } from '@app/components/header/header.component'
import { CardComponent } from '@app/components/card/card.component'
import { SimpleSliderComponent } from '@app/components/simple-slider/simple-slider.component'
import { MainHeaderComponent } from '@app/components/main-header/main-header.component'
import { FormsModule } from '@angular/forms'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { HttpClientModule } from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import {
  ModalModule,
  BsDropdownModule,
  BsDatepickerModule,
  TypeaheadModule,
  CollapseModule
} from 'ngx-bootstrap'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { MomentModule } from 'angular2-moment'
import { NgxCurrencyModule } from 'ngx-currency'
import { NgxPaginationModule } from 'ngx-pagination'
import { DataTableModule } from 'angular2-datatable'
import { Ng5SliderModule } from 'ng5-slider'
import { FooterComponent } from '@app/components/footer/footer.component'
import { AccordionModule } from 'ngx-bootstrap'
import { OwlModule } from 'ngx-owl-carousel'
import { AlertModule } from 'ngx-bootstrap/alert'
import { PipesModule } from './pipes/pipes.module'
import { ScrollComponent } from '@app/components/scroll/scroll.component'
import { SelectModule } from 'ng-select';
import { HeaderNewComponent } from '@app/components/header-new/header-new.component'

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderNewComponent,
    FooterComponent,
    CardComponent,
    MainHeaderComponent,
    SimpleSliderComponent,
    ScrollComponent,
    SigninPoppinComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    HttpClientModule,
    NgxCurrencyModule,
    NgxPaginationModule,
    DataTableModule,
    Ng5SliderModule,
    TypeaheadModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    OwlModule,
    AlertModule.forRoot(),
    PipesModule,
    SelectModule,
  ],
  exports: [
    HeaderComponent,
    HeaderNewComponent,
    FooterComponent,
    CardComponent,
    ScrollComponent,
    MainHeaderComponent,
    SimpleSliderComponent,
    SigninPoppinComponent,
    HttpClientModule,
    FormsModule,
    AccordionModule,
    CollapseModule,
    RouterModule,
    TabsModule,
    ReactiveFormsModule,
    DataTableModule,
    MomentModule,
    FormsModule,
    PopoverModule,
    ModalModule,
    NgxPaginationModule,
    BsDropdownModule,
    BsDatepickerModule,
    Ng5SliderModule,
    NgxCurrencyModule,
    TooltipModule,
    TypeaheadModule,
    OwlModule,
    AlertModule,
    PipesModule,
    SelectModule,

  ],
  providers: []
})
export class SharedModule {}
