import { ConnexionResolverService } from "./services/resolve/connexion-resolver.service";
import { PremiereCnxComponent } from "./premiere-cnx/premiere-cnx.component";
import { ResetPasswordEtablissementComponent } from "./connexion/reset-password-etablissement/reset-password-etablissement.component";
import { AddPasswordEtablissementComponent } from "./connexion/add-password-etablissement/add-password-etablissement.component";
import { ConnexionComponent } from "./connexion/connexion.component";
import { CardNumeriqueComponent } from "./connexion/forms/card-numerique/card-numerique.component";
import { SigninPoppinComponent } from "./connexion/forms/signin-poppin/signin-poppin.component";
import { AddPasswordComponent } from "./connexion/add-password/add-password.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NavigationPageComponent } from "./navigation-page/navigation-page.component";

import { LoginGuard } from "./services/authGurds/login.guard";
import { TestHomeComponent } from "@app/connexion/module-test/test-home.component";
import { ResetPasswordFormComponent } from "./connexion/reset-password-form/reset-password-form.component";
import { SignupComponent } from "./connexion/forms/signup/signup.component";
import { SigninComponent } from "./connexion/forms/signin/signin.component";
import { AccueilComponent } from "./main/accueil/accueil.component";
import { NousRejoindreComponent } from "./nous-rejoindre/nous-rejoindre.component";
import { NewHomeComponent } from "./main/new-home/new-home.component";
const routes: Routes = [
  {
    path: "oldHome",
    component: AccueilComponent,
  },
  {
    path: "",
    component: NewHomeComponent,
  },
  // {
  //   path: "connexion",
  //   loadChildren: () =>
  //     import("./connexion/connexion.module").then((m) => m.ConnexionModule),
  //   runGuardsAndResolvers: "always",
  //    resolve: { connexion: ConnexionResolverService },
  //   canActivate: [LoginGuard],
  // },
  {
    path: "connexion",
    component: ConnexionComponent,
    runGuardsAndResolvers: "always",
    canActivate: [LoginGuard],
  },
  {
    path: "home/:id",
    component: NavigationPageComponent,
    canActivate: [LoginGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "resetpassword/:id",
    component: ResetPasswordFormComponent,
    runGuardsAndResolvers: "always",
    canActivate: [LoginGuard],
  },
  {
    path: "resetpassword",
    component: ResetPasswordFormComponent,
    runGuardsAndResolvers: "always",
    canActivate: [LoginGuard],
  },
  {
    path: "addpassword/:id",
    component: AddPasswordComponent,
    runGuardsAndResolvers: "always",
    canActivate: [LoginGuard],
  },

  {
    path: "addpassword",
    component: AddPasswordComponent,
    runGuardsAndResolvers: "always",
    canActivate: [LoginGuard],
  },

  {
    path: "addPasswordEtablissement/:id",
    component: AddPasswordEtablissementComponent,
    runGuardsAndResolvers: "always",
    canActivate: [LoginGuard],
  },

  {
    path: "addPasswordEtablissemnt",
    component: AddPasswordEtablissementComponent,
    runGuardsAndResolvers: "always",
    canActivate: [LoginGuard],
  },

  {
    path: "resetPasswordEtablissement/:id",
    component: ResetPasswordEtablissementComponent,
    runGuardsAndResolvers: "always",
    canActivate: [LoginGuard],
  },

  {
    path: "resetPasswordEtablissement",
    component: ResetPasswordEtablissementComponent,
    runGuardsAndResolvers: "always",
    canActivate: [LoginGuard],
  },

  {
    path: "",
    loadChildren: () => import("./main/main.module").then((m) => m.MainModule),
  },

  {
    path: "",
    loadChildren: () =>
      import("./main-etablissement/main-etablissement.module").then(
        (m) => m.MainEtablissementModule
      ),
  },

  {
    path: "signup",
    component: SignupComponent,
  },

  {
    path: "sigin",
    component: SigninComponent,
  },
  {
    path: "sigin/:secteur",
    component: SigninComponent,
  },
  {
    path: "numCard",
    component: CardNumeriqueComponent,
  },
  {
    path: "inscription",
    component: NousRejoindreComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "premiereConnexion",
    component: PremiereCnxComponent,
    runGuardsAndResolvers: "always",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: "legacy",
      scrollPositionRestoration: "enabled",
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
