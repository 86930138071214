import { IOption } from "ng-select";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MonCompteService } from "./../services/mon-compte.service";
import { Component, OnInit, TemplateRef, Input, ViewChild,  ElementRef, Renderer2  } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs-compat";
import { BsModalRef, BsModalService, TabsetComponent } from "ngx-bootstrap";
@Component({
  selector: "mytribu-premiere-cnx",
  templateUrl: "./premiere-cnx.component.html",
  styleUrls: ["./premiere-cnx.component.scss"],
})
export class PremiereCnxComponent implements OnInit {
  constructor(
    private formBuilderAdherent: FormBuilder,
    public MonCompte: MonCompteService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    public router: Router,
    private elementRef: ElementRef, 
    private renderer: Renderer2  ) {
    this.selectedOption = "France";
    this.url = router.url; 
    //this.selectedCiv = 'M.';
  }
   
 
  private _unsubscribeAll: Subject<any> = new Subject();
  disabledBtn:any = false;
  TelephonePattern = "[0-9]{4,30}";
  EmailPattern = "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$";
  CodePostalPattern = "[a-zA-Z0-9]{0,}[0-9]{1,}[a-zA-Z0-9]{0,}";
  NamePattern = "[^0-9]{2,30}$";
  //NamePattern = "^(?![0-9]+$)[A-Za-z_-]{1,30}$";
  passPattern =
    "^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$";
  paramAdherentForm: FormGroup;
  segments: any = [];
  metiers: any = [];
  showPassword: any = false;
  showConfirmePassword: any = false;
  showInputMetier: any = false;
  Login: any = {
    paramAdherent: {},
    newslatters: false,
  };
  isClickedInside = true;
  values: Array<IOption> = [];
  checkNewsAdherent: any = false;
  checkCarteNum: any = false;
  errorMsg: any = "";
  errormessageUp: any = "";
  successMsg: any = "";
  alertSuccess = false;
  modalRef?: BsModalRef;
  click: any = false;
  submittedFormAdh = false;
  alert = false;
  nameEtab: any = "";
  idEtab: any = "";
  email: any = "";
  errormessage: any = "";
  dateNaissance: any = "";
  url: any = '';
  paramAdherent: any = {
    civilite: "",
    firstName: "",
    lastName: "",
    telephone: "",
    email: "",
    secteurs: "",
    metiers: "",
    dateNaissance: "",
    nameMetier: "",
    Name: "",
    password: "",
    confirmePassword: "",
    // document
    adresse: "",
    complementAdresse: "",
    codePostal: "",
    ville: "",
    carte: false,
    pays: "",
  };
  idClient: any = "";
  nameClient: any = "";
  idSecteur: any = "";
  nameSecteur: any = "";
  idMetier: any = "";
  nameMetier: any = "";
  selectedOption: any = "";
  selectedCiv: any = "";
  civiliteId: any = 1;
  public items: Array<string> = [
    "Afghanistan",
    "Afrique du Sud",
    "Albanie",
    "Algérie",
    "Allemagne",
    "Andorre",
    "Angola",
    "Anguilla",
    "Antarctique",
    "Antigua-et-Barbuda",
    "Antilles néerlandaises",
    "Arabie saoudite",
    "Argentine",
    "Arménie",
    "Aruba",
    "Australie",
    "Autriche",
    "Azerbaïdjan",
    "Bahamas",
    "Bahreïn",
    "Bangladesh",
    "Barbade",
    "Bélarus",
    "Belgique",
    "Belize",
    "Bénin",
    "Bermudes",
    "Bhoutan",
    "Bolivie",
    "Bosnie-Herzégovine",
    "Botswana",
    "Brésil",
    "Brunéi Darussalam",
    "Bulgarie",
    "Burkina Faso",
    "Burundi",
    "Cambodge",
    "Cameroun",
    "Canada",
    "Cap-Vert",
    "Ceuta et Melilla",
    "Chili",
    "Chine",
    "Chypre",
    "Colombie",
    "Comores",
    "Congo-Brazzaville",
    "Corée du Nord",
    "Corée du Sud",
    "Costa Rica",
    "Côte d’Ivoire",
    "Croatie",
    "Cuba",
    "Danemark",
    "Diego Garcia",
    "Djibouti",
    "Dominique",
    "Égypte",
    "El Salvador",
    "Émirats arabes unis",
    "Équateur",
    "Érythrée",
    "Espagne",
    "Estonie",
    "État de la Cité du Vatican",
    "États fédérés de Micronésie",
    "États-Unis",
    "Éthiopie",
    "Fidji",
    "Finlande",
    "France",
    "Gabon",
    "Gambie",
    "Géorgie",
    "Géorgie du Sud et les îles Sandwich du Sud",
    "Ghana",
    "Gibraltar",
    "Grèce",
    "Grenade",
    "Groenland",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernesey",
    "Guinée",
    "Guinée équatoriale",
    "Guinée-Bissau",
    "Guyana",
    "Guyane française",
    "Haïti",
    "Honduras",
    "Hongrie",
    "Île Bouvet",
    "Île Christmas",
    "Île Clipperton",
    "Île de l'Ascension",
    "Île de Man",
    "Île Norfolk",
    "Îles Åland",
    "Îles Caïmans",
    "Îles Canaries",
    "Îles Cocos - Keeling",
    "Îles Cook",
    "Îles Féroé",
    "Îles Heard et MacDonald",
    "Îles Malouines",
    "Îles Mariannes du Nord",
    "Îles Marshall",
    "Îles Mineures Éloignées des États-Unis",
    "Îles Salomon",
    "Îles Turks et Caïques",
    "Îles Vierges britanniques",
    "Îles Vierges des États-Unis",
    "Inde",
    "Indonésie",
    "Irak",
    "Iran",
    "Irlande",
    "Islande",
    "Israël",
    "Italie",
    "Jamaïque",
    "Japon",
    "Jersey",
    "Jordanie",
    "Kazakhstan",
    "Kenya",
    "Kirghizistan",
    "Kiribati",
    "Koweït",
    "Laos",
    "Lesotho",
    "Lettonie",
    "Liban",
    "Libéria",
    "Libye",
    "Liechtenstein",
    "Lituanie",
    "Luxembourg",
    "Macédoine",
    "Madagascar",
    "Malaisie",
    "Malawi",
    "Maldives",
    "Mali",
    "Malte",
    "Maroc",
    "Martinique",
    "Maurice",
    "Mauritanie",
    "Mayotte",
    "Mexique",
    "Moldavie",
    "Monaco",
    "Mongolie",
    "Monténégro",
    "Montserrat",
    "Mozambique",
    "Myanmar",
    "Namibie",
    "Nauru",
    "Népal",
    "Nicaragua",
    "Niger",
    "Nigéria",
    "Niue",
    "Norvège",
    "Nouvelle-Calédonie",
    "Nouvelle-Zélande",
    "Oman",
    "Ouganda",
    "Ouzbékistan",
    "Pakistan",
    "Palaos",
    "Panama",
    "Papouasie-Nouvelle-Guinée",
    "Paraguay",
    "Pays-Bas",
    "Pérou",
    "Philippines",
    "Pitcairn",
    "Pologne",
    "Polynésie française",
    "Porto Rico",
    "Portugal",
    "Qatar",
    "R.A.S. chinoise de Hong Kong",
    "R.A.S. chinoise de Macao",
    "régions éloignées de l’Océanie",
    "République centrafricaine",
    "République démocratique du Congo",
    "République dominicaine",
    "République tchèque",
    "Réunion",
    "Roumanie",
    "Royaume-Uni",
    "Russie",
    "Rwanda",
    "Sahara occidental",
    "Saint-Barthélémy",
    "Saint-Kitts-et-Nevis",
    "Saint-Marin",
    "Saint-Martin",
    "Saint-Pierre-et-Miquelon",
    "Saint-Vincent-et-les Grenadines",
    "Sainte-Hélène",
    "Sainte-Lucie",
    "Samoa",
    "Samoa américaines",
    "Sao Tomé-et-Principe",
    "Sénégal",
    "Serbie",
    "Serbie-et-Monténégro",
    "Seychelles",
    "Sierra Leone",
    "Singapour",
    "Slovaquie",
    "Slovénie",
    "Somalie",
    "Soudan",
    "Sri Lanka",
    "Suède",
    "Suisse",
    "Suriname",
    "Svalbard et Île Jan Mayen",
    "Swaziland",
    "Syrie",
    "Tadjikistan",
    "Taïwan",
    "Tanzanie",
    "Tchad",
    "Terres australes françaises",
    "Territoire britannique de l'océan Indien",
    "Territoire palestinien",
    "Thaïlande",
    "Timor oriental",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinité-et-Tobago",
    "Tristan da Cunha",
    "Tunisie",
    "Turkménistan",
    "Turquie",
    "Tuvalu",
    "Ukraine",
    "Union européenne",
    "Uruguay",
    "Vanuatu",
    "Venezuela",
    "Viêt Nam",
    "Wallis-et-Futuna",
    "Yémen",
    "Zambie",
    "Zimbabwe",
  ];
  premiere: any = {
    // adresse: "",
    // complementAdresse: "",
    // code: "",
    // ville: "",
    carte: false,
    //pays: ''
  };
  selectCivilite: any = false;
  Civilites: any = [];

  ngOnInit(): void {
    this.ListCivilites();
    this.detailAdh();

    this.paramAdherentForm = this.paramAdherentFormulaire();
    this.listActivites();
    this.getValuePays();
    this.nameEtab = localStorage.nameEtab;
    this.idEtab = localStorage.idEtab;
    this.idSecteur = localStorage.idSecteur;
    this.nameSecteur = localStorage.nameSecteur;
    this.idMetier = localStorage.idMetier;
    this.nameMetier = localStorage.nameMetier;
    this.email = localStorage.email;
    this.civiliteId = localStorage.civilite;
    this.secteurMetiers(this.idSecteur);
    console.log(
      this.paramAdherent.confirmePassword,
      this.paramAdherent.password,
      "test"
    );
    this.isClickedInside = true;
  }
  getValuePays() {
    this.values = this.items.map((el) => {
      return { value: el, label: el };
    });
    console.log(this.values, "values");
  }
  paramAdherentFormulaire(data?) {
    return this.formBuilderAdherent.group({
      civilite: [
        data && data.civilite ? data.civilite : "",
        [Validators.required],
      ],
      firstName: [
        data && data.firstName ? data.firstName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      lastName: [
        data && data.lastName ? data.lastName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      Name: [
        data && data.lastName ? data.lastName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      telephone: [
        data && data.telephone ? data.telephone : "",
        [Validators.required, Validators.pattern(this.TelephonePattern)],
      ],
      email: [
        data && data.email ? data.email : "",
        [Validators.required, Validators.pattern(this.EmailPattern)],
      ],
      secteurs: [
        data && data.secteurs ? data.secteurs : 1,
        [Validators.required],
      ],
      metiers: [data && data.metiers ? data.metiers : 1, [Validators.required]],
      nameMetier: [data && data.nameMetier ? data.nameMetier : ""],

      password: [
        data && data.password ? data.password : "",
        [Validators.required, Validators.pattern(this.passPattern)],
      ],
      confirmePassword: [
        data && data.confirmePassword ? data.confirmePassword : "",
        [Validators.required, Validators.pattern(this.passPattern)],
      ],
      dateNaissance: [
        data && data.dateNaissance ? data.dateNaissance : "",
        [Validators.required],
      ],
      pays: [data && data.pays ? data.pays : "", [Validators.required]],
      ville: [data && data.ville ? data.ville : "", [Validators.required]],
      adresse: [
        data && data.adresse ? data.adresse : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      complementAdresse: [
        data && data.complementAdresse ? data.complementAdresse : "",
        [Validators.pattern(this.NamePattern)],
      ],
      codePostal: [
        data && data.codePostal ? data.codePostal : "",
        [Validators.required],
      ],
    });
  }
  closeModalMoncompte(){
    this.LoginAdherentpremierCnx()
  }
  listActivites() {
    this.MonCompte.getActivites().subscribe((data) => {
      this.segments = data;
    });
  }
  checkBoxAdherent() {
    this.checkNewsAdherent = this.checkNewsAdherent ? false : true;
    this.Login.newslatters = this.checkNewsAdherent;
  }

  secteurMetiers(idSecteur) {
    this.MonCompte.getMetiersSecteur(idSecteur).subscribe((sm) => {
      this.metiers = sm;
    });
  }
  onSelect(value) {
    if (value === "Autre") {
      this.showInputMetier = true;
    } else {
      this.showInputMetier = false;
    }
    //this.selectedPatient.Price = reasonId;
  }
  scrollbarTop() {
    window.scroll(0, 0);
  }
  InscriptionPremiereCnx(template: TemplateRef<any>) {
    this.disabledBtn = true;
    document.getElementById('spinner').style.display = 'block';
    console.log('++++')
    //this.modalRef = this.modalService.show(template,Object.assign({}, { class: "container gray modal-xl " }));
    if (this.paramAdherentForm.errors === null) {
      this.Login.paramAdherent = this.paramAdherentForm.value;
      this.Login.newslatters = this.checkNewsAdherent;
      this.Login.codeUsageUq = localStorage.codeUsageUq;
      this.errorMsg = "";
      this.MonCompte.addClientPremiereCnx(this.Login)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            document.getElementById('spinner').style.display = 'none';
            this.idClient = res.result.id;
            this.nameClient = res.result.firstName + " " + res.result.lastName;
            this.scrollbarTop();
            this.successMsg =
              "Votre demande d’inscription a bien été prise en compte.";
            this.alertSuccess = true;
            setTimeout(() => {
              this.alertSuccess = false;
            }, 15000);
            this.modalRef = this.modalService.show(template,Object.assign({}, { class: "container gray modal-xl " })); 
            this.renderer.listen('document', 'click', (event) => {
              this.isClickedInside = this.elementRef.nativeElement.contains(event.target);
              if (!this.isClickedInside && event.srcElement.getAttribute("class") === "modal fade") {
                this.LoginAdherentpremierCnx(); 
              }
            });    
            this.viderAdherent();
            this.disabledBtn = false;
            this.click = true;
            const self = this;
            setTimeout(() => {}, 100);
            localStorage.clear();
            console.log("this.Login");
            console.log(this.Login);
            console.log(this.premiere);

            localStorage.setItem("codeUsageUq", this.premiere.codeUsageUq);
            localStorage.setItem("dossierExpired", res.user.dossierExpired);
            localStorage.setItem("client", JSON.stringify(res.user));
            localStorage.setItem("idClient", JSON.stringify(res.user.id));
            localStorage.setItem("token", res.token);
            //this.token.next(res.token);
            localStorage.setItem("clientIdentifiant", res.user.cleAdhesion);
            localStorage.setItem("idSecteur",this.Login.paramAdherent.secteurs);
            localStorage.setItem(
              "clientNonInscri",
              JSON.stringify(this.Login.paramAdherent)
            );

            this.toastrService.success("Votre demande a bien été envoyée");
            this.submittedFormAdh = false;
            this.checkNewsAdherent = false;
          },
          (errorlog) => {
            if (
              errorlog.err.error &&
              errorlog.err.error.message ===
                "Certains champs sont invalides : email"
            ) {
              this.errorMsg = "Email incorrect.";
            } else if (
              errorlog.err.error &&
              errorlog.err.error.message === "email existe."
            ) {
              this.errorMsg = "Email existe déja.";
              this.alert = true;
              setTimeout(() => {
                this.alert = false;
              }, 4000);
            }
          }
        );
    } else {
      this.scrollbarTop();
      this.alert = true;
      this.errorMsg =
        this.paramAdherent.firstName === ""
          ? " Veuillez remplir tous les champs requis."
          : " Veuillez remplir tous les champs requis.";
      this.successMsg = "";
      setTimeout(() => {
        this.alert = false;
      }, 10000);
      this.submittedFormAdh = true;
      return this.paramAdherentForm;
    }
  }
  viderAdherent() {
    this.paramAdherentForm.get("lastName").setValue("");
    this.paramAdherentForm.get("firstName").setValue("");
    this.paramAdherentForm.get("telephone").setValue("");
    this.paramAdherentForm.get("email").setValue("");
    this.paramAdherentForm.get("secteurs").setValue("");
    this.paramAdherentForm.get("metiers").setValue("");
    this.paramAdherentForm.get("nameMetier").setValue("");
    this.paramAdherentForm.get("Name").setValue("");
    this.paramAdherentForm.get("password").setValue("");
    this.paramAdherentForm.get("confirmePassword").setValue("");
    this.paramAdherentForm.get("pays").setValue("");
    this.paramAdherentForm.get("ville").setValue("");
    this.paramAdherentForm.get("codePostal").setValue("");
    this.paramAdherentForm.get("dateNaissance").setValue("");
    this.paramAdherentForm.get("adresse").setValue("");
    this.paramAdherentForm.get("complementAdresse").setValue("");
    this.checkNewsAdherent = false;
  }
  omit_special_char_tel(event) {
    let B;
    B = event.charCode; //         k = event.keyCode;  (Both can be used)
    return B >= 48 && B <= 57;
  }
   
  LoginAdherentpremierCnx() {
    this.isClickedInside = true;
    setTimeout(() => {
      this.modalRef.hide();
    }, 1500);
    // this.premiere.codeUsageUq = localStorage.codeUsageUq;

    // if (
    //   this.premiere.adresse != "" &&
    //   this.premiere.code != "" &&
    //   this.premiere.ville != ""
    // ) {
    //   this.MonCompte.updateClientPremiereCnx(this.idClient, this.premiere)
    //     .pipe(takeUntil(this._unsubscribeAll))
    //     .subscribe(
    //       (res) => {
    //         this.router.navigate(["home"]);
    //          this.modalRef.hide();
    //          localStorage.clear();
    //         localStorage.setItem("codeUsageUq", this.premiere.codeUsageUq);
    //         localStorage.setItem("dossierExpired", res.user.dossierExpired);
    //         localStorage.setItem("client", JSON.stringify(res.user));
    //         localStorage.setItem("token", res.token);
    //         //this.token.next(res.token);
    //         localStorage.setItem("clientIdentifiant",  res.user.cleAdhesion);
    //         if (localStorage.getItem("clientNonInscri")) {
    //           localStorage.removeItem("clientNonInscri");
    //         }
    //       },
    //       (MsgError) => {
    //         if (
    //           MsgError.err.error &&
    //           MsgError.err.error.message == "Mot de passe introuvable."
    //         ) {
    //           this.errormessageUp =
    //             "L’identifiant et/ou le mot de passe saisis sont incorrects.";
    //         } else if (
    //           MsgError.err.error &&
    //           MsgError.err.error.message == "login non trouvé."
    //         ) {
    //           this.errormessageUp =
    //             "L’identifiant et/ou le mot de passe saisis sont incorrects.";
    //         }

    //       }
    //     );
    // } else {
    //   this.errormessageUp = "Certains champs sont obligatoires";
    // }
    this.router.navigate(["home"]);
    
  }

  deconnexionPrmCnx() {
    this.modalRef.hide();
    localStorage.clear();
    this.router.navigate([""]);
  }

  detailAdh() {
    if (localStorage.idAdherent != 0) {
      this.MonCompte.detailAdherent(localStorage.idAdherent)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((data) => {
          this.paramAdherent = data.detailsClient;
          this.selectedCiv = data.detailsClient.civiliteId;
          this.dateNaissance = data.dateNaissance;
        });
      setTimeout(() => {
        this.civiliteId = this.selectedCiv;
      }, 3000);
    }
  }
  ListCivilites() {
    this.MonCompte.getCivilites()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res, "res");
        this.Civilites = res;
        
      })
      setTimeout(() =>{
        this.civiliteId = 1;

      }, 1000)
     
  }

  onModalClick(event: MouseEvent): void {
    // Check if the clicked element is outside the modal
    const isOutsideModal = !(event.target as HTMLElement).closest('.testModal');
    console.log(isOutsideModal, 'isOutsideModal')
    if (isOutsideModal) {
      // Call your desired function here
      this.closeModal();
    }
  }

  closeModal(): void {
    console.log('++++')
    // Perform any necessary actions to close the modal
  }
}
