import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
  HttpClient
} from '@angular/common/http'
import { throwError, Observable, BehaviorSubject, of } from 'rxjs'
import {
  map,
  catchError,
  filter,
  take,
  switchMap,
  finalize,
  flatMap
} from 'rxjs/operators'
import { AuthService } from '@app/services/auth.service'
import { environment } from 'environments/environment'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  )
  private token: string
  private url: any = environment.url
  constructor(
    public http: HttpClient,
    private auth: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = this.auth.getAuth()

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Vyront ' + token)
      })
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      })
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    })

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event
      }),
      catchError((error: HttpErrorResponse) => {
        if (
          error.error.message === 'Unauthorized' ||
          error.error.message === 'incorrect token' ||
          error.error.message === 'token expired'
        ) {
          const clientNonInscri = localStorage.getItem('clientNonInscri')
          if (!clientNonInscri) {
            localStorage.clear()
          }
          this.router.navigate(['connexion'])
        } else {
          return throwError({ err: error })
        }
      })
    )
  }
}
