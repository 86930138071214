import { Component, OnInit, Input } from '@angular/core'
import { environment } from 'environments/environment'

@Component({
  selector: 'mytribu-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() description: any = ''
  @Input() background: any = ''
  @Input() text: any = ''
  @Input() color: any = '#e2b00d'
  @Input() set icon(value) {
    this._icon = this.url + value
  }
  _icon: any = ''
  url: any = environment.urlImage

  constructor() {}

  ngOnInit() {
  }
}
