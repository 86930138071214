
import { takeUntil } from "rxjs/operators";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@app/services/auth.service";
import { environment } from "environments/environment";
import { Subject } from "rxjs-compat";
import { MonCompteService } from "@app/services/mon-compte.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { document } from "ngx-bootstrap";

@Component({
  selector: "mytribu-new-home",
  templateUrl: "./new-home.component.html",
  styleUrls: ["./new-home.component.scss"],
})
export class NewHomeComponent implements OnInit {
  public slider_header: any = [];
  pages: any;
  name: any;
  submitted = false;

  private _unsubscribeAll: Subject<any> = new Subject();
  orderId: any;
  contenu: any = [];
  url: any = environment.urlImage;
  urlPage: any;
  openMenu = true;
  showIndicator = false;
  itemsPerSlide = 1;
  singleSlideOffset = true;
  noWrap = true;
  scrWidth: any;
  client = "employeur";
  leradio: boolean = true;
  errorMsg:any ="";
  successMsg:any ="";
  demandeInfo: any = {
    nom: "",
    prenom: "",
    tel: "",
    email: ""
  }
  NamePattern = "[^0-9]{2,30}$";
  // TelephonePattern = "[0-9]{4,}";
  TelephonePattern = "^([0-9]){4,30}";
  EmailPattern = "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$";
  checkAcceptInfo: any = false;
  formHomePage: FormGroup;
  constructor(
    public auth: AuthService,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public monCompte: MonCompteService,
    private formBuilder: FormBuilder
  ) {}

  @HostListener("window:resize", ["$event"])
  ngAfterViewChecked() {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < 767) {
      if(window.scrollY !== 0){
        document.getElementById("bloc_nav").classList.remove("d-flex");
        document.getElementById("bloc_nav").style.display= "none"
      }else{
        document.getElementById("bloc_nav").classList.add("d-flex");
        document.getElementById("bloc_nav").style.display= "block"
      }
    }
  }
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < 767) {
      const elements = document.querySelectorAll(".secteur-med");
      document.querySelectorAll(".secteur-med").forEach((elem, index) => {
        elem.classList.remove("align-items-end");
        elem.classList.remove("justify-content-end");
      });

      this.itemsPerSlide = 1;
      console.log(this.itemsPerSlide, "++++");
    } else {
      this.itemsPerSlide = 4;
    }
    return this.itemsPerSlide;
    //console.log(this.itemsPerSlide, 'event')
  }

  ngOnInit() {
   
    this.formHomePage = this.formHP();
    console.log(this.formHomePage,'form')
    console.log("+++");
    if (window.screen.width > 767) {
      console.log("+++");
      this.itemsPerSlide = 1;
    }
    this.getScreenSize();
    console.log(this.itemsPerSlide, "on Init");
    this.getSlider();
    this.getDynamicPage();
    var bodyColor = "bodyColorWhite";
    document.getElementsByTagName("body")[0].className = bodyColor;
  }
  formHP(data?) {
    return this.formBuilder.group({
      
      nom: [data && data.nom ? data.nom : "", [Validators.required, Validators.pattern(this.NamePattern)]],
      prenom: [data && data.prenom ? data.prenom : "", [Validators.required, Validators.pattern(this.NamePattern)]],
      tel: [
        data && data.tel ? data.tel : null,
        [Validators.required],
      ],
      email: [
        data && data.email ? data.email : "",
        [Validators.required, Validators.pattern(this.EmailPattern)],
      ],
    });
  }
  acceptInfo(ev) {
    this.checkAcceptInfo = ev.target.checked ;
    if (this.checkAcceptInfo == true ){
      this.errorMsg='';
    }
  }
  ngOnDestroy() {
    // remove the the body classes
    var bodyColor = "bodyColor";
    document.getElementsByTagName("body")[0].className = bodyColor;
  }

  getSlider() {
    this.slider_header = [];
    this.auth
      .getSlider()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.slider_header = res;
        res.forEach((value, key) => {
          let descp = "";
          if (window.screen.width < 700 && value.description.length > 130) {
            descp = value.description.substr(0, 129) + "...";
          } else {
            descp = value.description;
          }
          this.slider_header[key]["descp"] = descp;
        });
      });
  }
  viderInfo() {
    this.formHomePage.get("nom").setValue("");
    this.formHomePage.get("prenom").setValue("");
    this.formHomePage.get("tel").setValue("");
    this.formHomePage.get("email").setValue("");
    this.checkAcceptInfo = false;
  }

  getDynamicPage() {
    this.auth
      .StaticPageDynamic()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.pages = res;
      });
  }

  navigate(url) {
    localStorage.setItem("pages", JSON.stringify(url));
    this.router.navigate(["/accueil/" + url]);
  }

  navigateSecteur(urlSecteur) {
    this.router.navigate(["/connexion"], { queryParams: { data: urlSecteur } });
    console.log(urlSecteur, "un sercteur activité");
  }
 /* navigateSecteur(urlSecteur){
    this.router.navigate(['/home'], {queryParams:{data:urlSecteur}});
    // this.secteurName = urlSecteur;
     console.log(urlSecteur,  "un sercteur activité", document.body.scrollHeight)
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
        });
  }*/
  salarie() {
    this.leradio = false;
  }

  employeur() {
    this.leradio = true;
  }
  sendsMailsFO() {
    this.errorMsg = "";
    this.successMsg = "";
    this.submitted = true;
    
    if (this.formHomePage.valid && this.checkAcceptInfo == true) {
      this.monCompte
      .sendsMailsHome(this.formHomePage.value)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.successMsg  = "Votre demande a bien été prise en compte";
        setTimeout(() => {
          this.successMsg  ="";
          this.viderInfo();
        }, 15000);
      });
    } else if (this.formHomePage.valid && this.checkAcceptInfo == false){
        this.errorMsg= "Merci de cocher cette case."
    }
  }

}
