import { Component, OnInit, ViewChild } from "@angular/core";
// import { AuthService } from "@app/services/auth.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MonCompteService } from "@app/services/mon-compte.service";

import { TabsetComponent } from "ngx-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@app/services/auth.service";
import { CookieService } from "ngx-cookie-service";
@Component({
  selector: "mytribu-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"]
})
export class SigninComponent implements OnInit {
  @ViewChild('tabset', {static: true}) tabset: TabsetComponent;
  client: any = {
    login: "",
    password: ""
  };
  resetPass = {
    email: ""
  };
  sendMail = false;
  click = false;
  errormessage: any = "";
  errormessageAdherent: any = "";
  errorPass: any = "";
  isCollapsed = false;
  clientIdentifiant: any;
  orderId: any;
  reponseManuel: any;
  successPass: any = "";
  showPassword: any = false;
  leradio:boolean=true;
  private _unsubscribeAll: Subject<any> = new Subject();
  constructor(
    public auth: AuthService,
    public router: Router,
    private route: ActivatedRoute,   
    private cookies: CookieService,
    public monCompte: MonCompteService
  ) {}
  secteurName: any;
  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      console.log("Les params")
      console.log(params)
      this.secteurName = params.data;
    })
  }

  /*** Login Client */
  LoginClient(myform) {
    // localStorage.clear()
    if (myform.valid == true) {
      this.errormessage = "";
      if (this.client.login != "" && this.client.password != "") {
        this.auth
          .LoginClient(this.client)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            res => {
              this.auth.setAuth(res.token);
              this.auth.setClient(res.user);
              this.errormessage = "";
              localStorage.setItem("dossierExpired", res.user.dossierExpired);
              this.clientIdentifiant = res.user.cleAdhesion;
              localStorage.setItem("clientIdentifiant", this.clientIdentifiant);
              localStorage.setItem("idEtabl", res.user.idEtabl);
              localStorage.setItem("idSecteur", res.user.idSecteur);
              localStorage.setItem("nameSecteur", res.user.nameSecteur);
              localStorage.setItem("infoCarte", res.user.infoCarte);
              localStorage.setItem("clientIdentifiant", this.clientIdentifiant);
              this.router.navigate(["home"], {
                queryParams: { modalConnexion: "true" }
              });
            },
            MsgError => {
              if (
                MsgError.err.error &&
                MsgError.err.error.message == "Mot de passe introuvable."
              ) {
                this.errormessage = "Mot de passe incorrect.";
              } else if (
                MsgError.err.error &&
                MsgError.err.error.message == "login non trouvé."
              ) {
                this.errormessage = "Nom d'utilisateur incorrect.";
              }
              this.router.navigate(["sigin"]);
            }
          );
      } else {
        this.errormessage =
          this.client.login == ""
            ? "Email où Clé d'adhésion obligatoire"
            : "Mot de passe obligatoire";
      }
    }
  }


  LoginAdherent(myform) {
    // localStorage.clear()
    if (myform.valid == true) {
      this.errormessageAdherent = "";
      if (this.client.login != "" && this.client.password != "") {
        this.auth
          .LoginClient(this.client)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            res => {
              this.auth.setAuth(res.token);
              this.auth.setClient(res.user);
              this.errormessageAdherent = "";
              localStorage.setItem("dossierExpired", res.user.dossierExpired);
              this.clientIdentifiant = res.user.cleAdhesion;
              localStorage.setItem("clientIdentifiant", this.clientIdentifiant);
              localStorage.setItem("idEtabl", res.user.idEtabl);
              localStorage.setItem("idSecteur", res.user.idSecteur);
              localStorage.setItem("nameSecteur", res.user.nameSecteur);
              localStorage.setItem("infoCarte", res.user.infoCarte);
              if (localStorage.getItem("clientNonInscri")) {
                localStorage.removeItem("clientNonInscri");
              }
                        
              this.router.navigate(["home"], {
                queryParams: { modalConnexion: "true" }
              });
            },
            MsgError => {
              if (
                MsgError.err.error &&
                MsgError.err.error.message == "Mot de passe introuvable."
              ) {
                this.errormessageAdherent = "Mot de passe incorrect.";
              } else if (
                MsgError.err.error &&
                MsgError.err.error.message == "login non trouvé."
              ) {
                this.errormessageAdherent = "Nom d'utilisateur incorrect.";
              }
              this.router.navigate(["sigin"]);
            }
          );
      } else {
        this.errormessageAdherent =
          this.client.login == ""
            ? "Email où Clé d'adhésion obligatoire"
            : "Mot de passe obligatoire";
      }
    }
  }

  /*** Reset Password */
  Resetpassword() {
    this.click = true;
    if (this.resetPass.email !== "") {
      this.monCompte
        .ResetPassword(this.resetPass)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          res => {
            this.errorPass = "";
            this.successPass = "Email envoyée avec succès.";
            let self = this;
            setTimeout(() => {
              self.successPass = "";
            }, 5000);
          },
          resError => {
            if (
              (resError.err.error &&
                resError.err.error.message == "Email incorrecte.") ||
              resError.err.error.message ==
                "Certains champs sont invalides : email"
            ) {
              this.errorPass = "Adresse email inconnue";
            }
          }
        );
    } else {
      this.errorPass =
        this.resetPass.email == "" ? "Ce Champ est obligatoire" : "";
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  salarie(){
    this.leradio=true;
  }
  employeur(){
    this.leradio=false;
  }
  selectTab(tabId?) {
    let id = tabId.id ? tabId.id : 0;
    this.tabset.tabs[id].active = true;
  }

}
