import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AuthService } from "@app/services/auth.service";
import { takeUntil, filter } from "rxjs/operators";
import { Subject } from "rxjs";
import { MonCompteService } from "@app/services/mon-compte.service";
import { Router } from "@angular/router";
import { IOption } from "ng-select";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { TabsetComponent } from "ngx-bootstrap";

@Component({
  selector: "mytribu-signup",
  templateUrl: "./signup.component.html",
  encapsulation: ViewEncapsulation.None,

  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  @ViewChild("tabset", { static: true }) tabset: TabsetComponent;
  private _unsubscribeAll: Subject<any> = new Subject();
  Login: any = {
    paramClient: {},
    paramAdherent: {},
    newslatters: {
      inscritMyTribu: false,
      inscritPartenaires: false,
    },
    mode: "0",
  };
  values: Array<IOption> = [];
  leradio: boolean = true;
  activites: any = [];
  metiers: any = [];

  public items: Array<string> = [
    "Afghanistan",
    "Afrique du Sud",
    "Albanie",
    "Algérie",
    "Allemagne",
    "Andorre",
    "Angola",
    "Anguilla",
    "Antarctique",
    "Antigua-et-Barbuda",
    "Antilles néerlandaises",
    "Arabie saoudite",
    "Argentine",
    "Arménie",
    "Aruba",
    "Australie",
    "Autriche",
    "Azerbaïdjan",
    "Bahamas",
    "Bahreïn",
    "Bangladesh",
    "Barbade",
    "Bélarus",
    "Belgique",
    "Belize",
    "Bénin",
    "Bermudes",
    "Bhoutan",
    "Bolivie",
    "Bosnie-Herzégovine",
    "Botswana",
    "Brésil",
    "Brunéi Darussalam",
    "Bulgarie",
    "Burkina Faso",
    "Burundi",
    "Cambodge",
    "Cameroun",
    "Canada",
    "Cap-Vert",
    "Ceuta et Melilla",
    "Chili",
    "Chine",
    "Chypre",
    "Colombie",
    "Comores",
    "Congo-Brazzaville",
    "Corée du Nord",
    "Corée du Sud",
    "Costa Rica",
    "Côte d’Ivoire",
    "Croatie",
    "Cuba",
    "Danemark",
    "Diego Garcia",
    "Djibouti",
    "Dominique",
    "Égypte",
    "El Salvador",
    "Émirats arabes unis",
    "Équateur",
    "Érythrée",
    "Espagne",
    "Estonie",
    "État de la Cité du Vatican",
    "États fédérés de Micronésie",
    "États-Unis",
    "Éthiopie",
    "Fidji",
    "Finlande",
    "France",
    "Gabon",
    "Gambie",
    "Géorgie",
    "Géorgie du Sud et les îles Sandwich du Sud",
    "Ghana",
    "Gibraltar",
    "Grèce",
    "Grenade",
    "Groenland",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernesey",
    "Guinée",
    "Guinée équatoriale",
    "Guinée-Bissau",
    "Guyana",
    "Guyane française",
    "Haïti",
    "Honduras",
    "Hongrie",
    "Île Bouvet",
    "Île Christmas",
    "Île Clipperton",
    "Île de l'Ascension",
    "Île de Man",
    "Île Norfolk",
    "Îles Åland",
    "Îles Caïmans",
    "Îles Canaries",
    "Îles Cocos - Keeling",
    "Îles Cook",
    "Îles Féroé",
    "Îles Heard et MacDonald",
    "Îles Malouines",
    "Îles Mariannes du Nord",
    "Îles Marshall",
    "Îles Mineures Éloignées des États-Unis",
    "Îles Salomon",
    "Îles Turks et Caïques",
    "Îles Vierges britanniques",
    "Îles Vierges des États-Unis",
    "Inde",
    "Indonésie",
    "Irak",
    "Iran",
    "Irlande",
    "Islande",
    "Israël",
    "Italie",
    "Jamaïque",
    "Japon",
    "Jersey",
    "Jordanie",
    "Kazakhstan",
    "Kenya",
    "Kirghizistan",
    "Kiribati",
    "Koweït",
    "Laos",
    "Lesotho",
    "Lettonie",
    "Liban",
    "Libéria",
    "Libye",
    "Liechtenstein",
    "Lituanie",
    "Luxembourg",
    "Macédoine",
    "Madagascar",
    "Malaisie",
    "Malawi",
    "Maldives",
    "Mali",
    "Malte",
    "Maroc",
    "Martinique",
    "Maurice",
    "Mauritanie",
    "Mayotte",
    "Mexique",
    "Moldavie",
    "Monaco",
    "Mongolie",
    "Monténégro",
    "Montserrat",
    "Mozambique",
    "Myanmar",
    "Namibie",
    "Nauru",
    "Népal",
    "Nicaragua",
    "Niger",
    "Nigéria",
    "Niue",
    "Norvège",
    "Nouvelle-Calédonie",
    "Nouvelle-Zélande",
    "Oman",
    "Ouganda",
    "Ouzbékistan",
    "Pakistan",
    "Palaos",
    "Panama",
    "Papouasie-Nouvelle-Guinée",
    "Paraguay",
    "Pays-Bas",
    "Pérou",
    "Philippines",
    "Pitcairn",
    "Pologne",
    "Polynésie française",
    "Porto Rico",
    "Portugal",
    "Qatar",
    "R.A.S. chinoise de Hong Kong",
    "R.A.S. chinoise de Macao",
    "régions éloignées de l’Océanie",
    "République centrafricaine",
    "République démocratique du Congo",
    "République dominicaine",
    "République tchèque",
    "Réunion",
    "Roumanie",
    "Royaume-Uni",
    "Russie",
    "Rwanda",
    "Sahara occidental",
    "Saint-Barthélémy",
    "Saint-Kitts-et-Nevis",
    "Saint-Marin",
    "Saint-Martin",
    "Saint-Pierre-et-Miquelon",
    "Saint-Vincent-et-les Grenadines",
    "Sainte-Hélène",
    "Sainte-Lucie",
    "Samoa",
    "Samoa américaines",
    "Sao Tomé-et-Principe",
    "Sénégal",
    "Serbie",
    "Serbie-et-Monténégro",
    "Seychelles",
    "Sierra Leone",
    "Singapour",
    "Slovaquie",
    "Slovénie",
    "Somalie",
    "Soudan",
    "Sri Lanka",
    "Suède",
    "Suisse",
    "Suriname",
    "Svalbard et Île Jan Mayen",
    "Swaziland",
    "Syrie",
    "Tadjikistan",
    "Taïwan",
    "Tanzanie",
    "Tchad",
    "Terres australes françaises",
    "Territoire britannique de l'océan Indien",
    "Territoire palestinien",
    "Thaïlande",
    "Timor oriental",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinité-et-Tobago",
    "Tristan da Cunha",
    "Tunisie",
    "Turkménistan",
    "Turquie",
    "Tuvalu",
    "Ukraine",
    "Union européenne",
    "Uruguay",
    "Vanuatu",
    "Venezuela",
    "Viêt Nam",
    "Wallis-et-Futuna",
    "Yémen",
    "Zambie",
    "Zimbabwe",
  ];
  alert = false;
  k: any;
  B: any;
  test = false;
  great = false;
  great2 = false;
  great3 = false;
  great4 = false;
  great5 = false;
  paramClientForm: FormGroup;
  paramAdherentForm: FormGroup;
  value: any = {};
  orderId: any;
  paramAdherent: any = {
    firstName: "",
    lastName: "",
    telephone: "",
    email: "",
    Name: "",
    RCS: "",
    adresse: "",
    Description: "",
  };
  paramClient: any = {
    secteurs: "",
    metiers: "",
    Name: "",
    adresse: "",
    telephoneContact: "",
    RCS: "",
    nbreSal: "",
    firstName: "",
    lastName: "",
    telephone: "",
    fonction: "",
    email: "",
    Description: "",
    // document: "",
  };
  promotion: any = {
    codePromo: "",
    montant: "",
  };
  newslatters: any = {
    inscritMyTribu: "false",
    inscritPartenaires: "false",
  };
  // paiement: any = {}
  code: any = {
    codePromo: "",
  };
  errorCode: any = "";
  success: any = "";
  errorInscri: any = "";
  successInscri: any = "";
  errormessage: any = "";
  Detailscode: any = {};
  Civilites: any = [];
  click: any = false;
  checked1 = false;
  checked2 = false;
  checked3 = false;
  conditionVente: any = "";
  civility: any = "";
  // recpatcha: any = false;
  // myRecaptcha: boolean;
  price: any;
  duree: any;
  // recap: any = false;
  banque: any = "";
  showPassword: any = false;
  showConfirmePassword: any = false;
  passPattern =
    "^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9d$@$!%*?&].{7,}$";
  NamePattern = "[^0-9]{2,30}$";
  TelephonePattern = "[0-9]{4,}";
  EmailPattern =
    "^[a-zA-Z]([._-]?[a-zA-Z0-9])*@[a-zA-Z]([.-]?[a-zA-Z])*(.[a-zA-Z]{2,3})+$";
  CodePostalPattern = "[a-zA-Z0-9]{0,}[0-9]{1,}[a-zA-Z0-9]{0,}";

  constructor(
    public auth: AuthService,
    public router: Router,
    public MonCompte: MonCompteService,
    private formBuilder: FormBuilder,
    private formBuilderAdherent: FormBuilder,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.paramClientForm = this.paramClientFormulaire();
    this.paramAdherentForm = this.paramAdherentFormulaire();
    /*    let clientNonInscri = localStorage.getItem("clientNonInscri");
       let promotion = localStorage.getItem("promotion");
       if (clientNonInscri) {
         if (promotion) {
           this.Detailscode = JSON.parse(promotion);
         }
         this.paramClient = JSON.parse(clientNonInscri);
       } */
    // this.listMetiers();
    this.listActivites();
    this.ListCivilites();
    this.Price();
    // this.recpatcha = false;
    this.values = this.items.map((el) => {
      return { value: el, label: el };
    });
    console.log(this.success);
  }
  paramAdherentFormulaire(data?) {
    return this.formBuilderAdherent.group({
      Name: [
        data && data.Name ? data.Name : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      adresse: [
        data && data.adresse ? data.adresse : null,
        [Validators.required, Validators.pattern(this.CodePostalPattern)],
      ],
      telephone: [
        data && data.telephone ? data.telephone : null,
        [Validators.required, Validators.pattern(this.TelephonePattern)],
      ],
      RCS: [data && data.RCS ? data.RCS : "", [Validators.required]],
      firstName: [
        data && data.firstName ? data.firstName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      lastName: [
        data && data.lastName ? data.lastName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      email: [
        data && data.email ? data.email : "",
        [Validators.required, Validators.pattern(this.EmailPattern)],
      ],
      Description: [
        data && data.Description ? data.Description : null,
        [Validators.required],
      ],
      // document: [data && data.document ? data.document : null],
    });
  }
  paramClientFormulaire(data?) {
    return this.formBuilder.group({
      secteurs: [
        data && data.secteurs ? data.secteurs : 1,
        [Validators.required],
      ],
      metiers: [data && data.metiers ? data.metiers : 1, [Validators.required]],
      Name: [
        data && data.Name ? data.Name : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      adresse: [
        data && data.adresse ? data.adresse : null,
        [Validators.required, Validators.pattern(this.CodePostalPattern)],
      ],
      telephoneContact: [
        data && data.telephoneContact ? data.telephoneContact : null,
        [Validators.required, Validators.pattern(this.TelephonePattern)],
      ],
      RCS: [data && data.RCS ? data.RCS : "", [Validators.required]],
      nbreSal: [
        data && data.nbreSal ? data.nbreSal : "",
        [Validators.required],
      ],
      firstName: [
        data && data.firstName ? data.firstName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      lastName: [
        data && data.lastName ? data.lastName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      telephone: [
        data && data.telephone ? data.telephone : null,
        [Validators.required, Validators.pattern(this.TelephonePattern)],
      ],
      fonction: [
        data && data.fonction ? data.fonction : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      email: [
        data && data.email ? data.email : "",
        [Validators.required, Validators.pattern(this.EmailPattern)],
      ],
      Description: [
        data && data.Description ? data.Description : null,
        [Validators.required],
      ],
      // document: [data && data.document ? data.document : null],
    });
  }

  patternChange(pattern) {
    console.log(pattern, "pattern");
    if (pattern.control.errors == null || pattern.control.error === undefined) {
      this.great = true;
    } else {
      this.great = false;
    }
  }
  patternChangeName(pattern) {
    console.log(pattern, "pattern");
    if (
      pattern.control.errors == null ||
      !pattern.control.error === undefined
    ) {
      this.great2 = true;
    } else {
      this.great2 = false;
    }
  }
  patternChangeTelephone(pattern) {
    console.log(pattern, "pattern");
    if (
      pattern.control.errors == null ||
      !pattern.control.error === undefined
    ) {
      this.great3 = true;
    } else {
      this.great3 = false;
    }
  }
  patternChangeEmail(pattern) {
    console.log(pattern, "pattern");
    if (
      pattern.control.errors == null ||
      !pattern.control.error === undefined
    ) {
      this.great4 = true;
    } else {
      this.great4 = false;
    }
  }
  patternChangeCodePostal(pattern) {
    console.log(pattern, "pattern");
    if (
      pattern.control.errors == null ||
      !pattern.control.error === undefined
    ) {
      this.great5 = true;
    } else {
      this.great5 = false;
    }
  }
  /*** Pays ***/
  public selected(value: any): void {
    console.log("Selected value is: ", value);
  }

  public removed(value: any): void {
    console.log("Removed value is: ", value);
  }

  public typed(value: any): void {
    console.log("New search input: ", value);
  }

  public refreshValue(value: any): void {
    this.value = value;
  }
  /*** Pays ***/

  /*** recaptchaa ***/
  /*   onScriptLoad() {
    console.log("Google reCAPTCHA loaded and is ready for use!");
    this.recpatcha = true;
  } */

  /*   onScriptError() {
    console.log("Something went long when loading the Google reCAPTCHA");
  } */
  /*** recaptchaa ***/

  InscriptionAdherent() {
    if (this.paramAdherentForm.valid) {
      this.Login.paramAdherent = this.paramAdherentForm.value;
      this.Login.mode = "0";
      this.errorInscri = "";

      this.MonCompte.InscriptionAdherent(this.Login)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.paramAdherentForm.reset();
            this.successInscri = "Inscription effectuée avec succès.";
            this.click = true;
            let self = this;
            setTimeout(() => {}, 100);
            localStorage.setItem(
              "clientNonInscri",
              JSON.stringify(this.Login.paramAdherent)
            );
            this.toastrService.success("Votre demande a bien été envoyée");
          },
          (errorlog) => {
            console.log("requette echouée");
            if (
              errorlog.err.error &&
              errorlog.err.error.message ==
                "Certains champs sont invalides : email"
            ) {
              this.errorInscri = "Email incorrect.";
            } else if (
              errorlog.err.error &&
              errorlog.err.error.message == "email existe."
            ) {
              this.errorInscri = "Email existe déja.";
              this.alert = true;
              setTimeout(() => {
                this.alert = false;
              }, 4000);
            }
          }
        );
    } else {
      this.alert = true;
      this.errorInscri =
        this.paramAdherent.firstName == ""
          ? " Veuillez remplir tous les champs requis."
          : " Veuillez remplir tous les champs requis.";
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    }
  }

  InscriptionClient() {
    if (this.paramClientForm.valid) {
      this.Login.paramClient = this.paramClientForm.value;
      this.Login.mode = "0";
      this.errorInscri = "";

      this.MonCompte.Inscription(this.Login)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.paramClientForm.reset();
            this.successInscri = "Inscription effectuée avec succès.";
            this.click = true;
            let self = this;
            setTimeout(() => {}, 100);
            localStorage.setItem(
              "clientNonInscri",
              JSON.stringify(this.Login.paramClient)
            );
            this.toastrService.success("Votre demande a bien été envoyée");
          },
          (errorlog) => {
            console.log("test");
            if (
              errorlog.err.error &&
              errorlog.err.error.message ==
                "Certains champs sont invalides : email"
            ) {
              this.errorInscri = "Email incorrect.";
            } else if (
              /*              else if (
              errorlog.err.error &&
              errorlog.err.error.message == "Mot de passe non confirmé"
            ) {
              this.errorInscri = "Vérifier le mot de passe de confirmation";
            } */
              errorlog.err.error &&
              errorlog.err.error.message == "email existe."
            ) {
              this.errorInscri = "Email existe déja.";
              this.alert = true;
              setTimeout(() => {
                this.alert = false;
              }, 4000);
            }
          }
        );
    } else {
      this.alert = true;
      this.errorInscri =
        this.paramClient.firstName == ""
          ? " Veuillez remplir tous les champs requis."
          : " Veuillez remplir tous les champs requis.";
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    }
  }

  Price() {
    this.MonCompte.ProductPrice()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.price = res.prix;
        this.duree = res.duree;
      });
  }
  ListCivilites() {
    this.MonCompte.getCivilites()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.Civilites = res;
        for (let index = 0; index < this.Civilites.length; index++) {
          const el = this.Civilites[index];
          this.civility = el.displayName;
        }
      });
  }

  Notifmail(ev) {
    this.conditionVente = "";
  }

  Banque() {
    const paiement = {
      montant: this.Detailscode.montantApres
        ? this.Detailscode.montantApres
        : this.price,
      idCivilite: this.paramClient.idCivilite,
      firstName: this.paramClient.firstName,
      lastName: this.paramClient.lastName,
      email: this.paramClient.email,
      telephone: this.paramClient.telephone,
      pays: this.paramClient.pays,
      codePostal: this.paramClient.codePostal,
      password: this.paramClient.password,
      confirmePassword: this.paramClient.confirmePassword,
      codePromo: this.promotion.codePromo ? this.promotion.codePromo : "",
      inscritMyTribu: this.newslatters.inscritMyTribu,
      inscritPartenaires: this.newslatters.inscritPartenaires,
      type: "Inscription",
    };

    const paiementBanque = {
      paiement: paiement,
    };
    this.MonCompte.paiementBanque(paiementBanque)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(async (res) => {
        this.banque = await res.html;
        localStorage.setItem("orderId", res.orderId);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  // erreur inscrit
  Test() {
    if (
      this.paramClient.idCivilite === "" ||
      this.paramClient.firstName === "" ||
      this.paramClient.lastName === "" ||
      this.paramClient.email === "" ||
      this.paramClient.telephone === "" ||
      this.paramClient.pays === "" ||
      this.paramClient.codePostal === ""
    ) {
      this.alert = true;
      this.test = true;
      this.errorInscri = "Veuillez remplir tous les champs requis.";
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    }
    if (!this.checked1) {
      this.conditionVente =
        "Merci d’accepter les conditions générales de ventes";
      setTimeout(() => {
        this.conditionVente = "";
      }, 3000);
    }
  }
  omit_special_char(event) {
    let k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 8 ||
      k === 32 ||
      (k >= 48 && k <= 57)
    );
  }
  omit_special_char_tel(event) {
    let B;
    B = event.charCode; //         k = event.keyCode;  (Both can be used)
    return B >= 48 && B <= 57;
  }

  salarie() {
    this.leradio = false;
  }
  employeur() {
    this.leradio = true;
  }

  selectTab(tabId?) {
    let id = tabId.id ? tabId.id : 0;
    this.tabset.tabs[id].active = true;
  }

  listActivites() {
    this.MonCompte.getActivites().subscribe((data) => {
      this.activites = data;
      console.log("Voici les activites");
      console.log(data);
    });
  }

  // listMetiers() {
  //   this.MonCompte.getMetiers()
  //   .subscribe(data => {
  //       this.metiers = data;
  //       console.log("Voici les metiers");
  //       console.log(data);
  //   })
  // }
  // console.log(activites.target.value);
  // onSelect(activites){

  onSelect(activites) {
    console.log("Les activites");
    console.log(activites.target.value);
    this.MonCompte.getMetiers().subscribe((data) => {
      this.metiers = data;
      console.log("Les metiers");
      console.log(this.metiers);
    });
  }

  // }

  /*onSelect(activites){
    this.MonCompte.getMetiers().filter(metier  => metier.id == activites.target.value);
  }
  */
}
