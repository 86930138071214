import { Component, OnInit, ViewChild } from "@angular/core";
import { TabsetComponent } from "ngx-bootstrap";

@Component({
  selector: "mytribu-card-numerique",
  templateUrl: "./card-numerique.component.html",
  styleUrls: ["./card-numerique.component.scss"],
})
export class CardNumeriqueComponent implements OnInit {
  @ViewChild("tabset", { static: true }) tabset: TabsetComponent;
  conditionVente: any = "";
  checked1 = false;
  checked2 = false;
  checked3 = false;
  success: any = "";
  errorInscri: any = "";
  successInscri: any = "";
  alert = false;
  constructor() {}

  ngOnInit(): void {}

  avantageAdherent() {}

  deconnexionAdherent() {}

  Notifmail(ev) {
    this.conditionVente = "";
  }

  selectTab(tabId?) {
    let id = tabId.id ? tabId.id : 0;
    this.tabset.tabs[id].active = true;
  }
}
