
import { Component, OnInit, ViewChild } from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '@app/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MonCompteService} from '@app/services/mon-compte.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'mytribu-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  private Slider: any = [];
  pages: any;
  name: any;
  private _unsubscribeAll: Subject<any> = new Subject();
  orderId: any;
  contenu: any = [];

  constructor(public auth: AuthService, public activeRoute: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.getSlider();
    this.getDynamicPage();
  }

  getSlider() {
    console.log('test');
    this.Slider = [];
    this.auth
      .getSlider()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.Slider = res;
      });
  }
 /* navigateGeneriquePage(item) {
    localStorage.setItem('pages', JSON.stringify(item.segment));
    this.router.navigate(['/home/' + item.segment]);
  }*/
  getDynamicPage() {
    this.auth
      .StaticPageDynamic()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.pages = res;
      })
  }

    navigate(url) {
    localStorage.setItem('pages', JSON.stringify(url));
    this.router.navigate(['/home/' + url]);
  }

  navigateSecteur(urlSecteur){
    this.router.navigate(['/home'], {queryParams:{data:urlSecteur}});
    // this.secteurName = urlSecteur;
     console.log(urlSecteur,  "un sercteur activité", document.body.scrollHeight)
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
        });
  }
}
