import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouterServiceService {

  // navigate: any;
  // constructor(  private router: RouterServiceService ) {}

  /*
  public set_router( routing , route  ) {
    // router without any data in url ..... ...........................
    if ( routing.data === false ) {
      if ( routing.relative === true ) {
        this.router.navigate( [ routing.path ], { relativeTo: route } );
      } else {
        this.router.navigate( [ routing.path ] );
      }
      return;
    }

    // router with data in url ..........................................
    let paramsInUrl: any = {};
    if ( routing.data instanceof Array ) {   //  array of object with params ..........................................

        for ( const param of routing.data ) { // loop
            paramsInUrl[param.keyparams] = param.params.toString();
        }
    } else { // single param ....................................
        paramsInUrl = { [routing.data.keyparams] : routing.data.params.toString() };
    }

    if ( routing.relative === true) {
      this.router.navigate( [ routing.path ], { relativeTo: route ,  queryParams: paramsInUrl } );
    } else {
      this.router.navigate( [ routing.path ], { queryParams: paramsInUrl } );
    }
    return;
  }*/
}
