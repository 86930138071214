import { takeUntil } from "rxjs/operators";
import { Component, Input, OnInit, ViewChild ,HostListener} from "@angular/core";
import { TabsetComponent } from "ngx-bootstrap";
import { Subject } from "rxjs-compat";
import { IOption } from "ng-select";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@app/services/auth.service";
import { Router } from "@angular/router";
import { MonCompteService } from "@app/services/mon-compte.service";
import { ToastrService } from "ngx-toastr";

export interface ISecteurParam {
  id: number;
  libelle: string;
}

@Component({
  selector: "app-nous-rejoindre",
  templateUrl: "./nous-rejoindre.component.html",
  styleUrls: ["./nous-rejoindre.component.scss"],
})
export class NousRejoindreComponent implements OnInit {
  @ViewChild("tabset", { static: true }) tabset: TabsetComponent;
  private _unsubscribeAll: Subject<any> = new Subject();
  Login: any = {
    paramClient: {},
    paramAdherent: {},
    newslatters: false,
    mode: "0",
  };
  conditionVente: any = "";
  values: Array<IOption> = [];
  activites: any = [];
  metiers: any = [];
  fonctions: any;
  secteur = "employeur";
  showIndicator = false;
  showInputMetier = false;
  itemsPerSlide = 1;
  success: any = "";
  errorInscri: any = "";
  errorInscriClient: any = "";
  successInscri: any = "";
  successInscriClient: any = "";
  errormessage: any = "";
  submittedFormClient = false;
  submittedFormAdh = false;
  checkNewsClient: any = false;
  checkNewsAdherent: any = false;
  leradio: boolean = true;
  scrWidth:any;
  essentielSal: boolean = true;
  premiumSal: boolean = false;

  essentiel: boolean = true;
  premium: boolean = false;

  great = false;
  great2 = false;
  great3 = false;
  great4 = false;
  great5 = false;
  alert = false;
  alertSuccess = false;
  alertClient = false;
  alertClientSuccess = false;
  click: any = false;
  secteurs_form: any[];
  passPattern =
    "^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9d$@$!%*?&].{7,}$";
  NamePattern = "[^0-9]{2,30}$";
  // TelephonePattern = "[0-9]{4,}";
  TelephonePattern = "^([0-9]){4,30}";
  EmailPattern = "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$";
  CodePostalPattern = "[a-zA-Z0-9]{0,}[0-9]{1,}[a-zA-Z0-9]{0,}";
  paramClientForm: FormGroup;
  paramAdherentForm: FormGroup;
  urlPage: any;
  paramClient: any = {
    secteurs: 1,
    metiers: "",
    Name: "",
    adresse: "",
    telephoneContact: "",
    RCS: "",
    nbreSal: "",
    firstName: "",
    lastName: "",
    telephone: "",
    fonction: "",
    email: "",
    Description: "",
    document: "",
    
  };
  paramAdherent: any = {
    firstName: "",
    lastName: "",
    telephone: "",
    email: "",
    secteurs: "",
    metiers: "",
    Name: "",
    RCS: "",
    adresse: "",
    Description: "",
    // document
  };
  selectedOptionActEmp: string = "";
  selectedOptionMetEmp: string = "";
  selectedOptionACtAdh: string = "";
  selectedOptionMetAdh: string = "";
  selectedOptionFonctionEm: string = "";
  selectedOptionFonctionAdherent: string = "";
  checked2 = false;
  checkedA2 = false;
  checked3 = false;

  newslatters: any = {
    inscritMyTribu: false,
    inscritPartenaires: false,
  };
  singleSlideOffset = true;
  noWrap = true;
  slider_avantages = [
    {
      title: "Parc de loisirs",
      image: "assets/v3/images/avantages/parc.png",
      id_block: "parc",
    },
    {
      title: "Prêt-à-porter",
      image: "assets/v3/images/avantages/pretaporter.png",
      id_block: "pretaporter",
    },
    {
      title: "Sports & Loisirs",
      image: "assets/v3/images/avantages/sports.png",
      id_block: "sports",
    },
    {
      title: "Transports",
      image: "assets/v3/images/avantages/transport.png",
      id_block: "transport",
    },
    {
      title: "Bien-être",
      image: "assets/v3/images/avantages/bienetre.png",
      id_block: "bienetre",
    },
    {
      title: "Cinéma",
      image: "assets/v3/images/avantages/cinema.png",
      id_block: "cinema",
    },
    {
      title: "Concerts & Spectacles",
      image: "assets/v3/images/avantages/concerts.png",
      id_block: "concerts",
    },
    {
      title: "Vie quotidienne",
      image: "assets/v3/images/avantages/viequotidienne.png",
      id_block: "viequotidienne",
    },
    {
      title: "Destination vacances",
      image: "assets/v3/images/avantages/vacances.png",
      id_block: "vacances",
    },
    {
      title: "Location",
      image: "assets/v3/images/avantages/location.png",
      id_block: "location",
    },
  ];
  pages: any;
  constructor(
    public auth: AuthService,
    public router: Router,
    public MonCompte: MonCompteService,
    private formBuilder: FormBuilder,
    private formBuilderAdherent: FormBuilder,
    private toastrService: ToastrService
  ) {}
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
        console.log(this.scrWidth, 'xidth', event)
        if (this.scrWidth < 767){
          this.itemsPerSlide = 1;
          console.log(this.itemsPerSlide, '++++')
        }else {
          this.itemsPerSlide = 4;
        }
        return this.itemsPerSlide;
        //console.log(this.itemsPerSlide, 'event')
  }
  ngOnInit(): void {
    if (window.screen.width > 767){
      console.log("+++")
      this.itemsPerSlide = 1;
    }
    this.getScreenSize();
    this.paramClientForm = this.paramClientFormulaire();
    this.paramAdherentForm = this.paramAdherentFormulaire();
    this.listActivites();
    this.listFonctions();
    this.getDynamicPage();
  }
  fileName = "";
  paramClientFormulaire(data?) {
    return this.formBuilder.group({
      secteurs: [
        data && data.secteurs ? data.secteurs : 1,
        [Validators.required],
      ],
      metiers: [data && data.metiers ? data.metiers : 1, [Validators.required]],
      nameMetier: [data && data.nameMetier ? data.nameMetier :""],
      fonction: [
        data && data.fonction ? data.fonction : 1,
        [Validators.required],
      ],
      Name: [data && data.Name ? data.Name : "", [Validators.required]],
      adresse: [
        data && data.adresse ? data.adresse : null,
        [Validators.required],
      ],
      telephoneContact: [
        data && data.telephoneContact ? data.telephoneContact : null,
        [Validators.required, Validators.pattern(this.TelephonePattern)],
      ],
      RCS: [data && data.RCS ? data.RCS : "", [Validators.required]],
      nbreSal: [
        data && data.nbreSal ? data.nbreSal : "",
        [Validators.required, Validators.min(1)],
      ],
      firstName: [
        data && data.firstName ? data.firstName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      lastName: [
        data && data.lastName ? data.lastName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      telephone: [
        data && data.telephone ? data.telephone : null,
        [Validators.required, Validators.pattern(this.TelephonePattern)],
      ],
      email: [
        data && data.email ? data.email : "",
        [Validators.required, Validators.pattern(this.EmailPattern)],
      ],
      Description: [data && data.Description ? data.Description : null],
      // myTribu: [true],

      // document: [data && data.document ? data.document : null],
    });
  }
  paramAdherentFormulaire(data?) {
    return this.formBuilderAdherent.group({
      firstName: [
        data && data.firstName ? data.firstName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      lastName: [
        data && data.lastName ? data.lastName : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      telephone: [
        data && data.telephone ? data.telephone : null,
        [Validators.required, Validators.pattern(this.TelephonePattern)],
      ],
      email: [
        data && data.email ? data.email : "",
        [Validators.required, Validators.pattern(this.EmailPattern)],
      ],
      secteurs: [
        data && data.secteurs ? data.secteurs : 1,
        [Validators.required],
      ],
      metiers: [data && data.metiers ? data.metiers : 1, [Validators.required]],
      nameMetier: [data && data.nameMetier ? data.nameMetier :""],
      Name: [
        data && data.Name ? data.Name : "",
        [Validators.required, Validators.pattern(this.NamePattern)],
      ],
      RCS: [data && data.RCS ? data.RCS : ""],
      adresse: [
        data && data.adresse ? data.adresse : null,
        [Validators.required],
      ],
      Description: [data && data.Description ? data.Description : null],
      myTribu: [true],
      // document: [data && data.document ? data.document : null],
    });
  }

  patternChange(pattern) {
    console.log(pattern, "pattern");
    if (pattern.control.errors == null || pattern.control.error === undefined) {
      this.great = true;
    } else {
      this.great = false;
    }
  }
  patternChangeName(pattern) {
    console.log(pattern, "pattern");
    if (
      pattern.control.errors == null ||
      !pattern.control.error === undefined
    ) {
      this.great2 = true;
    } else {
      this.great2 = false;
    }
  }
  patternChangeTelephone(pattern) {
    console.log(pattern, "pattern");
    if (
      pattern.control.errors == null ||
      !pattern.control.error === undefined
    ) {
      this.great3 = true;
    } else {
      this.great3 = false;
    }
  }
  patternChangeEmail(pattern) {
    console.log(pattern, "pattern");
    if (
      pattern.control.errors == null ||
      !pattern.control.error === undefined
    ) {
      this.great4 = true;
    } else {
      this.great4 = false;
    }
  }
  patternChangeCodePostal(pattern) {
    console.log(pattern, "pattern");
    if (
      pattern.control.errors == null ||
      !pattern.control.error === undefined
    ) {
      this.great5 = true;
    } else {
      this.great5 = false;
    }
  }

  InscriptionClient() {
    if (this.paramClientForm.valid) {
      console.log("ParamClient values");

      console.log(this.paramClientForm.value);
      this.Login.paramClient = this.paramClientForm.value;
      this.Login.essentiel = this.essentiel;
      this.Login.premium = this.premium;
      this.Login.newslatters = this.checkNewsClient;
      this.Login.mode = "0";
      this.errorInscriClient = "";
      console.log("dedans");

      this.MonCompte.Inscription(this.Login)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.scrollbarTop();
            this.alertClientSuccess = true;
            this.successInscriClient =
              "Votre demande d’inscription a bien été prise en compte. Notre ambassadeur vous contactera dans les plus brefs délais afin de finaliser votre inscription.";
            setTimeout(() => {
              this.alertClientSuccess = false;
            }, 15000);
            this.viderEmployeur();
            this.click = true;
            const self = this;
            setTimeout(() => {}, 100);
            localStorage.setItem(
              "clientNonInscri",
              JSON.stringify(this.Login.paramClient)
            );
            this.toastrService.success("Votre demande a bien été envoyée");
            this.submittedFormClient = false;
            this.checkNewsClient = false;
          },
          (errorlog) => {
            console.log("test");
            if (
              errorlog.err.error &&
              errorlog.err.error.message ===
                "Certains champs sont invalides : email"
            ) {
              this.errorInscriClient = "Email incorrect.";
            } else if (
              /*              else if (
              errorlog.err.error &&
              errorlog.err.error.message == "Mot de passe non confirmé"
            ) {
              this.errorInscri = "Vérifier le mot de passe de confirmation";
            } */
              errorlog.err.error &&
              errorlog.err.error.message === "email existe."
            ) {
              this.errorInscriClient = "Email existe déja.";
              this.alertClient = true;
              setTimeout(() => {
                this.alertClient = false;
              }, 4000);
            }
          }
        );
    } else {
      this.scrollbarTop();
      this.alertClient = true;
      this.errorInscriClient =
        this.paramClient.firstName === ""
          ? " Veuillez remplir tous les champs requis."
          : " Veuillez remplir tous les champs requis.";
      this.successInscriClient = "";
      setTimeout(() => {
        this.alertClient = false;
        // this.submittedFormClient = false;
      }, 10000);
      this.submittedFormClient = true;
      return this.paramClientForm;
    }
  }

  InscriptionAdherent() {
    if (this.paramAdherentForm.valid) {
      this.Login.paramAdherent = this.paramAdherentForm.value;
      this.Login.newslatters = this.checkNewsAdherent;
      this.Login.mode = "0";
      this.errorInscri = "";
      this.Login.essentiel = this.essentielSal;
      this.Login.premium = this.premiumSal;

      this.MonCompte.InscriptionAdherent(this.Login)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.scrollbarTop();
            this.successInscri =
              "Votre demande d’inscription a bien été prise en compte. Notre ambassadeur vous contactera dans les plus brefs délais afin de finaliser votre inscription.";
            this.alertSuccess = true;
            setTimeout(() => {
              this.alertSuccess = false;
            }, 15000);
            this.viderAdherent();
            this.click = true;
            const self = this;
            setTimeout(() => {}, 100);
            localStorage.setItem(
              "clientNonInscri",
              JSON.stringify(this.Login.paramAdherent)
            );
            this.toastrService.success("Votre demande a bien été envoyée");
            this.submittedFormAdh = false;
            this.checkNewsAdherent = false;
          },
          (errorlog) => {
            console.log("requette echouée");
            if (
              errorlog.err.error &&
              errorlog.err.error.message ===
                "Certains champs sont invalides : email"
            ) {
              this.errorInscri = "Email incorrect.";
            } else if (
              errorlog.err.error &&
              errorlog.err.error.message === "email existe."
            ) {
              this.errorInscri = "Email existe déja.";
              this.alert = true;
              setTimeout(() => {
                this.alert = false;
              }, 4000);
            }
          }
        );
    } else {
      this.scrollbarTop();
      this.alert = true;
      this.errorInscri =
        this.paramAdherent.firstName === ""
          ? " Veuillez remplir tous les champs requis."
          : " Veuillez remplir tous les champs requis.";
      this.successInscri = "";
      setTimeout(() => {
        this.alert = false;
      }, 10000);
      this.submittedFormAdh = true;
      return this.paramAdherentForm;
    }
  }

  omit_special_char(event) {
    let k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 8 ||
      k === 32 ||
      (k >= 48 && k <= 57)
    );
  }
  omit_special_char_tel(event) {
    let B;
    B = event.charCode; //         k = event.keyCode;  (Both can be used)
    return B >= 48 && B <= 57;
  }

  omit_special_char_nbreA(event) {
    let B;
    B = event.charCode; //         k = event.keyCode;  (Both can be used)
    return B >= 48 && B <= 57;
  }

  selectTab(tabId?) {
    const id = tabId.id ? tabId.id : 0;
    this.tabset.tabs[id].active = true;
  }

  listActivites() {
    this.MonCompte.getActivites().subscribe((data) => {
      this.activites = data;
      // const secteur = data.find((obj) => {
      //   return obj.id == 1;
      // });
      // console.log('Voici les secteurs ' + this.activites)
    });
  }

  secteurMetiers(secteurs) {
    const id_secteur_form = secteurs.target.value;
    // let secteur: ISecteurParam = null;
    // récupération d'un secteur à partir du tableau des secteurs stocké en amant
    /*this.MonCompte.getOnActiviteById().subscribe((data) => {
      console.log(data.id);
    });*/
    // console.log(secteur);
    this.MonCompte.getMetiersSecteur(id_secteur_form).subscribe((sm) => {
      this.metiers = sm;
    });
  }

  listFonctions() {
    this.MonCompte.getFonctions().subscribe((data) => {
      this.fonctions = data;
      console.log("Voici les fonctions");
      console.log(data);
    });
  }
  scrollbarTop() {
    window.scroll(0, 0);
  }

  Notifmail(ev) {
    this.conditionVente = "";
  }

  checkBoxClient() {
    this.checkNewsClient = this.checkNewsClient ? false : true;

    this.Login.newslatters = this.checkNewsClient;
  }

  checkBoxAdherent() {
    this.checkNewsAdherent = this.checkNewsAdherent ? false : true;

    this.Login.newslatters = this.checkNewsAdherent;
  }

  salarie() {
    this.leradio = false;
    this.successInscriClient = "";
    this.errorInscriClient = "";
    // this.paramClientForm.reset();
    this.viderEmployeur();
    this.submittedFormClient = false;
    this.metiers = [];
    this.showInputMetier = false;
  }

  employeur() {
    this.leradio = true;
    this.successInscri = "";
    this.errorInscri = "";
    this.viderAdherent();
    this.submittedFormAdh = false;
    this.metiers = [];
    this.showInputMetier = false;
  }
  essentielSalarie() {
    this.essentielSal = true;
    this.premiumSal = false;
  }
  premiumSalarie(){
    this.essentielSal = false;
    this.premiumSal = true;
  }
  essentielEmpl() {
    this.essentiel = true;
    this.premium = false;
  }
  premiumEmpl(){
    this.essentiel = false;
    this.premium = true;
  }
  viderAdherent() {
    this.paramAdherentForm.get("lastName").setValue("");
    this.paramAdherentForm.get("firstName").setValue("");
    this.paramAdherentForm.get("telephone").setValue("");
    this.paramAdherentForm.get("email").setValue("");
    this.paramAdherentForm.get("secteurs").setValue("");
    this.paramAdherentForm.get("metiers").setValue("");
    this.paramClientForm.get("nameMetier").setValue("");
    this.paramAdherentForm.get("Name").setValue("");
    this.paramAdherentForm.get("RCS").setValue("");
    this.paramAdherentForm.get("adresse").setValue("");
    this.paramAdherentForm.get("Description").setValue("");
    this.checkNewsAdherent = false;
  }

  viderEmployeur() {
    this.paramClientForm.get("secteurs").setValue("");
    this.paramClientForm.get("metiers").setValue("");
    this.paramClientForm.get("Name").setValue("");
    this.paramClientForm.get("nameMetier").setValue("");
    this.paramClientForm.get("adresse").setValue("");
    this.paramClientForm.get("telephoneContact").setValue("");
    this.paramClientForm.get("RCS").setValue("");
    this.paramClientForm.get("nbreSal").setValue("");
    this.paramClientForm.get("lastName").setValue("");
    this.paramClientForm.get("firstName").setValue("");
    this.paramClientForm.get("telephone").setValue("");
    this.paramClientForm.get("fonction").setValue("");
    this.paramClientForm.get("email").setValue("");
    this.paramClientForm.get("Description").setValue("");
    this.checkNewsClient = false;
  }

  onSelect(value) {
    if (value === 'Autre') {
      this.showInputMetier = true;
    } else {
      this.showInputMetier = false;
    }
    //this.selectedPatient.Price = reasonId;
  }
  onSelectSecteur(value){
    this.showInputMetier = false;
  }
  navigateGeneriquePage(item,id_block){

    console.log("navigateGeneriquePage")
    console.log(item)
    console.log(id_block)
    localStorage.setItem('pages', JSON.stringify(item));
    this.router.navigate(['/home/' + item.segment]);
    
  }
  getDynamicPage() {
    this.auth
    .StaticPageDynamic()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(res => {
      this.pages = res;
    })
}
}