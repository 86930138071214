import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import {AccueilComponent} from '@app/main/accueil/accueil.component';
import {AccueilRoutingModule} from '@app/main/accueil/accueil-routing.module';
import {CarouselModule} from 'ngx-bootstrap';

@NgModule({
  declarations: [AccueilComponent],
    imports: [CommonModule, AccueilRoutingModule, SharedModule, CarouselModule]
})
export class AccueilModule {}
