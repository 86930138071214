import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { Router } from '@angular/router';
declare var require: any
var CryptoTS = require("crypto-ts");
@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {
secretKey: any = environment.cryptKey;
  constructor(private router: Router) { }
  encryptData(value) {
    return CryptoTS.AES.encrypt(value, this.secretKey).toString()
  }
  decryptData(value) {
    try {
    let tmpCrypt = CryptoTS.AES.decrypt(value, this.secretKey).toString(CryptoTS.enc.Utf8);
    if (tmpCrypt) {
      return tmpCrypt
    }
   } 
  catch (err) {
      this.router.navigate(['/'])
    }
  }
}
