import { Component, OnInit, ViewChild, HostListener} from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "@app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { environment } from "../../../environments/environment";

@Component({
  selector: "mytribu-accueil",
  templateUrl: "./accueil.component.html",
  styleUrls: ["./accueil.component.scss"],
})
export class AccueilComponent implements OnInit {
  public slider_header: any = [];
  pages: any;
  name: any;
  private _unsubscribeAll: Subject<any> = new Subject();
  orderId: any;
  contenu: any = [];
  url: any = environment.urlImage;
  urlPage: any;
  openMenu = true;
  showIndicator = false;
  itemsPerSlide = 1;
  singleSlideOffset = true;
  noWrap = true;
  scrWidth:any;
  slider_avantages = [
    {
      title: "Parc de loisirs",
      image: "assets/v3/images/avantages/parc.png",
      id_block: "parc",
    },
    {
      title: "Prêt-à-porter",
      image: "assets/v3/images/avantages/pretaporter.png",
      id_block: "pretaporter",
    },
    {
      title: "Sports & Loisirs",
      image: "assets/v3/images/avantages/sports.png",
      id_block: "sports",
    },
    {
      title: "Transports",
      image: "assets/v3/images/avantages/transport.png",
      id_block: "transport",
    },
    {
      title: "Bien-être",
      image: "assets/v3/images/avantages/bienetre.png",
      id_block: "bienetre",
    },
    {
      title: "Cinéma",
      image: "assets/v3/images/avantages/cinema.png",
      id_block: "cinema",
    },
    {
      title: "Concerts & Spectacles",
      image: "assets/v3/images/avantages/concerts.png",
      id_block: "concerts",
    },
    {
      title: "Vie quotidienne",
      image: "assets/v3/images/avantages/viequotidienne.png",
      id_block: "viequotidienne",
    },
    {
      title: "Destination vacances",
      image: "assets/v3/images/avantages/vacances.png",
      id_block: "vacances",
    },
    {
      title: "Location",
      image: "assets/v3/images/avantages/location.png",
      id_block: "location",
    },
  ];

  constructor(
    public auth: AuthService,
    public activeRoute: ActivatedRoute,
    public router: Router
  ) {}
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
        if (this.scrWidth < 767){
          const elements = document.querySelectorAll('.secteur-med');
          document.querySelectorAll('.secteur-med').forEach((elem, index)  => {
            elem.classList.remove('align-items-end');
            elem.classList.remove('justify-content-end');
          })

         
          this.itemsPerSlide = 1;
          console.log(this.itemsPerSlide, '++++')
        }else {
          this.itemsPerSlide = 4;
        }
        return this.itemsPerSlide;
        //console.log(this.itemsPerSlide, 'event')
  }
  ngOnInit() {

    console.log("+++")
    if (window.screen.width > 767){
      console.log("+++")
      this.itemsPerSlide = 1;
    }
    this.getScreenSize();
    console.log(this.itemsPerSlide, 'on Init')
    this.getSlider();
    this.getDynamicPage();
    var bodyColor = "bodyColorWhite";
    document.getElementsByTagName("body")[0].className = bodyColor;
  }
  ngOnDestroy() {
    // remove the the body classes
    var bodyColor = "bodyColor";
    document.getElementsByTagName("body")[0].className = bodyColor;
  }
  getSlider() {
    this.slider_header = [];
    this.auth
      .getSlider()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.slider_header = res;
        res.forEach((value, key) => {
           let descp = '';
           if(window.screen.width < 700 && value.description.length > 130){
              descp = value.description.substr(0, 129) + '...';
           } else {
              descp = value.description;
            }
            this.slider_header[key]['descp'] = descp;
        });
      });
  }

  navigateGeneriquePage(item){

    localStorage.setItem('pages', JSON.stringify(item));
    this.router.navigate(['/home/' + item.segment]);
    
  }
  getDynamicPage() {
    this.auth
      .StaticPageDynamic()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.pages = res;
      });
  }

  navigate(url) {
    localStorage.setItem("pages", JSON.stringify(url));
    this.router.navigate(["/accueil/" + url]);
  }

  // navigateSecteur(urlSecteur) {
  //   this.router.navigate(["/sigin"], { queryParams: { data: urlSecteur } });
  // }
  navigateSecteur(urlSecteur) {
    this.router.navigate(["/connexion"], { queryParams: { data: urlSecteur } });
    console.log(urlSecteur,"un sercteur activité");
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
      });
  }
}
