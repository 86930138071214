import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '@app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { MonCompteService } from '@app/services/mon-compte.service';

import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

@Component({
  selector: 'mytribu-reset-password-etablissement',
  templateUrl: './reset-password-etablissement.component.html',
  styleUrls: ['./reset-password-etablissement.component.scss'],
})
export class ResetPasswordEtablissementComponent implements OnInit {
  timedOut = false;
  lastPing?: Date = null;
  constructor(
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: BsModalService,
    public monCompte: MonCompteService,
    //private idle: Idle,
    //private keepalive: Keepalive
  ) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    // idle.setIdle(60);
    // // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    // idle.setTimeout(60);
    // // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document.
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onTimeout.subscribe(() => {
    //   this.timedOut = true;
    // });

    // sets the ping interval to 15 seconds
    // keepalive.interval(15);

    // keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }
  @ViewChild('tabset', { static: true }) tabset: TabsetComponent;
  // @Input() id: any
  id: any;
  modalRef?: BsModalRef;
  pass: any = {
    password: '',
    confirmePassword: '',
  };
  resetPass = {
    email: '',
  };
  errorPass: any = '';
  successPass: any = '';
  errormessage: any = '';
  click = false;
  success: any = '';
  showPassword: any = false;
  showConfirmePassword: any = false;
  passPattern =
    '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$';
  private _unsubscribeAll: Subject<any> = new Subject();

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id') || '';
    });
  }

  Reset(pass) {
    const resetpassword: any = {
      password: this.pass.password,
    };
    if (
      this.pass.password != '' &&
      this.pass.confirmePassword != '' && 
      this.pass.password == this.pass.confirmePassword 
      ) {
      this.auth
        .resetPasswordEtablissement(this.id, resetpassword)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.pass.password = '';
            this.pass.confirmePassword = '';
            this.success = 'Votre mot de passe a été modifié avec succès';
            const self = this;
        
            setTimeout(() => {
              this.pass.password = '';
              this.pass.confirmePassword = '';
              this.success = 'Votre mot de passe a été modifié avec succès';
              self.success = '';
          
              this.router.navigate(['connexion']);
            }, 5000);
            
          },
          (MsgError) => {
            if (MsgError.err.error && MsgError.err.error.message === 'Password exist.') {
              this.errormessage = `Le mot de passe n'a pas pu être modifié, car ce mot de passe est déjà utilisé.`;
            } else if ( MsgError.err.error && MsgError.err.error.message === 'Mot de passe existe.') {
              this.errormessage = `Le mot de passe n'a pas pu être modifié, car ce mot de passe est déjà utilisé.`;
            } else if (MsgError.err.error && this.timedOut === true) {
              this.errormessage = `Le mot de passe n’a pas pu être modifié, car le lien a expiré. Cliquez
                                    <a href="#" class="color-secondary">ici</a>
                                    Pour recevoir un nouveau lien de réinitialisation de mot de passe,`;
            }
            this.pass.password = '';
            this.pass.confirmePassword = '';
            setTimeout(() => {
              this.errormessage = '';
            }, 10000);
          }
        );
    }   else {
      this.errormessage =
        this.pass.password == ''
          ? 'Veuillez remplir tous les champs requis.'
          : 'Veuillez remplir tous les champs requis.'
    } 
  }

  selectTab(tabId?) {
    const id = tabId.id ? tabId.id : 0;
    this.tabset.tabs[id].active = true;
  }

  openModalEtablissement(templateEtablissement: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateEtablissement);
  }

  /*** Reset Password employeur*/
  ResetpasswordEmployeur() {
    this.click = true;
    if (this.resetPass.email !== '') {
      this.monCompte
        .ResetPasswordEmployeur(this.resetPass)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            this.errorPass = '';
            this.successPass =
              'Un email pour réinitialiser votre mot de passe vous a été envoyé. Pensez à vérifier vos courriers indésirables si vous ne l\'avez pas reçu.';
            this.resetPass.email = '';
            const self = this;
            setTimeout(() => {
              self.successPass = '';
            }, 10000);
          },
          (resError) => {
            if (
              (resError.err.error &&
                resError.err.error.message == 'Email incorrecte.') ||
              resError.err.error.message ==
                'Certains champs sont invalides : email'
            ) {
              this.errorPass =
                'Désolé, cette adresse e-mail n\'est associée à aucun compte Etablissement.';
            } else if (resError.err.error &&
              resError.err.error.message == "Compte inactif.") 
           {
            this.errorPass =
              "Votre compte n’est plus actif, veuillez contacter notre service client pour une demande de réactivation .";
          }
          }
        );
    } else {
      this.errorPass =
        this.resetPass.email == '' ? 'Ce Champ est obligatoire' : '';
    }
  }

  ferme() {
    this.modalRef.hide();
    this.resetPass.email = '';
  }
}
